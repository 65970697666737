<template>
    <div>
      <nav class="navbar wrapper" v-if="internalNavbar">
              <div class="logo">
                <a href="../">
                  <img src="../../assets/images/brand/calculico-logo.svg" height="100%" alt="">
                </a>
              </div>
              <div class="icon-text">
                <a href="/"><img src="../../assets/images/icons/home-solid.svg" alt="Ir para a home"><span>Todas as Calculadoras</span></a>
              </div>
            </nav>
            <nav class="navbar wrapper" v-else>
              <div class="logo">
                <a href="../">
                  <img src="../../assets/images/brand/calculico-logo.svg" height="100%" alt="">
                </a>
              </div>
            </nav>
      <nav class="navbar sticky wrapper" v-show="isSticky">
        <div class="logo">
          <a href="./">
            <img src="../../assets/images/brand/calculico-logo.svg" height="100%" alt="">
          </a>
        </div>
        <AppButton
            label="Ir para o topo"
            size="small"
            type="outline"
            :scrollToTop="true"/>
      </nav>
    </div>
  </template>
  
  <script>
import AppButton from '../common/appButton.vue';

  export default {
    name: 'AppHeader',
    components: {
      AppButton,
    },
    props:{
      internalNavbar: Boolean,
    },
    data() {
      return {
        isSticky: false,
        lastScrollTop: 0,
        searchQuery: '', // Adiciona a searchQuery aqui
      };
    },
    watch: {
      searchQuery(newValue) {
        this.$emit('updateSearchQuery', newValue);
      },
    },
    mounted() {
          window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
          window.removeEventListener('scroll', this.handleScroll);
        },
    methods: {
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 200) {
          if (scrollTop > this.lastScrollTop) {
            this.isSticky = true;
          } else {
            this.isSticky = false;
          }
        } else {
          this.isSticky = false;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  nav.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
  
    @include bp(mobile) {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;

      .logo{
        align-self: center;
      }
    }

    .icon-text{
      @include bp(mobile){
        span{
          display: none;
        }
        img{
          width: 20px;
        }

      }
    }
  
    &.sticky {
      position: fixed;
      box-sizing: border-box;
      top: 0;
      width: 100%;
      z-index: 109990;
      border: 1px solid $primary-1;
      background: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(12px);
      padding-top:2.4rem;
      padding-bottom:2.4rem;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

      @include bp(mobile) {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        border-radius: 12px;
        width: auto;
        top:.4rem;
        left:.4rem;
        right:.4rem;
      }

      .logo{
        align-self: center;
      }
    }
  }
  
  .logo {
    align-self: flex-start;
    height: 40px;
  
    @include bp(mobile) {
      height: 32px;
    }
  }
  </style>
  