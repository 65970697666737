<template>
  <BreadCrumb :items="breadCrumbItems" />
  <div class="wrapper content">
    <adHorizontal />
    <CalculatorHeader emoji="🔥" title="Calculadora de Taxa Metabólica Basal (TMB)"
      subtitle="Calcule a quantidade de calorias que seu corpo precisa em repouso." />
    <div class="calculator-body">
      <form @submit.prevent="calculateTMB">
        <div class="form-section">
          <div class="form-group">
            <label for="gender">Gênero:</label>
            <select v-model="gender" id="gender" required>
              <option value="" disabled selected>Selecione</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </select>
          </div>
          <div class="form-group">
            <label for="weight">Peso (kg):</label>
            <input type="number" v-model="weight" id="weight" placeholder="kg" required />
          </div>
          <div class="form-group">
            <label for="height">Altura (cm):</label>
            <input type="number" v-model="height" id="height" placeholder="cm" required />
            <caption>Informe sua altura em cm. Por exemplo, se você mede 1,70 digite 170.</caption>
          </div>
          <div class="form-group">
            <label for="age">Idade:</label>
            <input type="number" v-model="age" id="age" placeholder="anos" required />
          </div>
        </div>
        <AppButton label="Calcular" type="primary" size="large" />
      </form>
      <div v-if="tmbResult !== null" class="results-separator">
        <h4>Resultado</h4>
        <hr />
      </div>
      <div v-if="tmbResult !== null" class="result" ref="result">
        <h5>Sua TMB</h5>
        <h3>{{ tmbResult }} kcal/dia</h3>
      </div>
    </div>
  </div>
  <!--PRODUCT OFFERS AFFILIATE PROGRAM AMAZON-->
  <div class="category product-shelf">
    <div class="wrapper">
      <div class="category-header">
        <div class="category-title">
          <img src="@/assets/images/icons/gem-solid.svg" width="32" alt="Category Icon" class="category-icon" />
          <h3>Monitores de gasto calórico, peso e exercícios recomendados</h3>
        </div>
        <p class="large">Selecionamos monitores de frequência cardíaca e smartwatches que você pode gostar:</p>
      </div>
      <div class="calculator-cards">
        <ProductCard title="Xiaomi Mi Band 8 Active versão global (Preto)"
          description="Monitoramento de frequência cardíaca de pulso | Mais de 50 modos esportivos | Bateria com duração de 14 dias | Tela ultragrande de 1,47 polegadas | Carregamento magnético | Conexão Bluetooth"
          url="https://amzn.to/4690dry" image="https://m.media-amazon.com/images/I/71XmFzlEc6L._AC_SX679_.jpg" />
        <ProductCard title="Monitor de atividades e monitoramento de atividades Mi Smart Band 8 Xiaomi"
          description="Menos de R$300 | Carregamento rápido e bateria que dura mais de 16 dias | Tela de 60hz | Monitoramento de oxigenação | Mais de 150 modos fitness para monitorar calorias queimadas | Mais de 200 estilos de mostradores"
          url="https://amzn.to/4f0jOhn" image="https://m.media-amazon.com/images/I/51X6dyFVFpL._AC_SY679_.jpg" />
        <!--AMAZON PRIME CARD
        <div class="grid-ad amazon-prime">
          <a href="https://amzn.to/3zJaqhU">
            <img src="@/assets/images/amazon-prime-day3.jpg" alt=""></a>
          <p>Usuários do Calculico tem 30 dias grátis para testar Amazon Prime e aproveitar ofertas e benefícios, como
            entrega grátis e mais rápida.</p>
          <AppButton label="Testar Grátis por 30 dias" type="secondary" href="https://amzn.to/3zJaqhU" />
          <caption>Você será direcionado para a página da Amazon Prime</caption>
        </div>
        AMAZON PRIME CARD END-->
        <ProductCard title="SAMSUNG Galaxy FIT 3 Tela AMOLED de 1,6 polegadas"
          description="Mais de 100 modos de exercício | Vida útil da bateria de 14 dias | Mais de 100 mostradores de relógio | Mais de 100 modos de exercício | Modelo internacional - (preto)"
          url="https://amzn.to/3Lmo3q9" image="https://m.media-amazon.com/images/I/41s4LbRyUDL._AC_SX569_.jpg" />
        <ProductCard title="Amazfit Band 7 Fitness & Health Tracker com Alexa integrada"
          description="Bateria de 18 dias | Alexa embutida | Tela AMOLED de 1,47 ” | Freqüência cardíaca e Monitoramento SPO₂ | 120 modos esportivos"
          url="https://amzn.to/3W8E08a" image="https://m.media-amazon.com/images/I/31PQ5QtHDBL._AC_SX679_.jpg" />
        <ProductCard title="Xiaomi Balança Digital Corporal MI BODY COMPOSITION SCALE 2, Branco"
          description="Melhor custo-benefício, menos de R$200 | Chip BIA de alta precisão controle do percentual de gordura corporal | Teste de equilíbrio | Controle por aplicativo | Design moderno e minimalista"
          url="https://amzn.to/468ZS8g"
          image="https://m.media-amazon.com/images/I/51ytwQsTQJL.__AC_SX300_SY300_QL70_ML2_.jpg" />
      </div>
      <div class="alert-warning">
        <img src="@/assets/images/icons/exclamation-circle-solid.svg" alt="">
        <p class="small">Os produtos acima são selecionados e recomendados por nós, mas são vendidos e entregues de
          acordo com as informações no site do parceiro, no caso, a Amazon. O Calculico não é responsável pela venda ou
          entrega. Como associado da Amazon, recebemos por compras qualificadas.</p>
      </div>
    </div>
  </div>
  <!--END OF OFFERS AFFILIATE PROGRAM AMAZON-->
  <OtherCalculators :categoryId="3" />
  <div class="calculatorInfo wrapper content">
    <section class="info-block">
      <header>
        <h2>O que é a TMB?</h2>
      </header>
      <p class="large">A Taxa Metabólica Basal (TMB) é a quantidade mínima de calorias que o corpo necessita para manter
        as funções vitais em repouso, como respirar, circular o sangue e regular a temperatura corporal. É um método
        matemático, embora inexato, de calcular as necessidades calóricas diárias para garantir que o corpo continue
        funcionando corretamente sem a necessidade de atividade física. As principais funções vitais que a TMB sustenta
        incluem o funcionamento do coração, cérebro, pulmões e intestinos.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Importância da TMB</h2>
      </header>
      <p class="large">Conhecer a sua TMB é fundamental para planejar dietas e regimes de exercícios eficazes. Ela ajuda
        a entender quantas calorias seu corpo necessita diariamente em repouso, o que é crucial para manter ou ajustar
        seu peso. A TMB varia de acordo com diversos fatores, incluindo idade, sexo, composição corporal e níveis
        hormonais. Pessoas com mais massa muscular, por exemplo, tendem a ter uma TMB mais alta.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Como Calcular a TMB?</h2>
      </header>
      <p class="large">Existem várias fórmulas para calcular a TMB, sendo as mais comuns a Equação de Harris-Benedict e
        a Equação de Mifflin-St Jeor.</p>
      <p class="large">
        <strong>Equação de Harris-Benedict:</strong><br>
        <strong>Para homens:</strong> TMB = 66 + (13,7 × Peso(kg)) + (5 × Altura(cm)) - (6,8 × Idade(anos))<br>
        <strong>Para mulheres:</strong> TMB = 655 + (9,6 × Peso(kg)) + (1,8 × Altura(cm)) - (4,7 × Idade(anos))
      </p>
      <p class="large">
        <strong>Equação de Mifflin-St Jeor:</strong><br>
        <strong>Para homens:</strong> TMB = (10 × Peso(kg)) + (6,25 × Altura(cm)) - (5 × Idade(anos)) + 5<br>
        <strong>Para mulheres:</strong> TMB = (10 × Peso(kg)) + (6,25 × Altura(cm)) - (5 × Idade(anos)) - 161
      </p>
    </section>

    <section class="info-block">
      <header>
        <h2>Fatores de Atividade</h2>
      </header>
      <p class="large">Para obter uma estimativa mais precisa das necessidades calóricas diárias, é importante
        considerar o nível de atividade física. Isso é feito multiplicando a TMB pelo fator de atividade correspondente:
      </p>
      <p class="large">
        <strong>Sedentário:</strong> 1.2 (pouco ou nenhum exercício)<br>
        <strong>Levemente ativo:</strong> 1.375 (exercício leve 1 a 3 dias por semana)<br>
        <strong>Moderadamente ativo:</strong> 1.55 (exercício moderado, esporte 3 a 5 dias por semana)<br>
        <strong>Altamente ativo:</strong> 1.725 (exercício pesado 5 a 6 dias por semana)<br>
        <strong>Extremamente ativo:</strong> 1.9 (exercício pesado diariamente e até 2 vezes por dia)
      </p>
    </section>

    <section class="info-block">
      <header>
        <h2>Como Usar a TMB para Perda de Peso</h2>
      </header>
      <p class="large">Para perder peso, é essencial consumir menos calorias do que o seu corpo necessita para manter o
        peso atual. Com base na sua TMB e no seu nível de atividade, você pode calcular a quantidade total de calorias
        que deve ingerir diariamente. Por exemplo, se sua TMB é de 1500 kcal e você é moderadamente ativo (fator de
        atividade 1.55), suas necessidades calóricas diárias seriam:</p>
      <p class="large"><strong>Necessidades Calóricas Diárias = 1500 × 1.55 = 2325 kcal</strong></p>
      <p class="large">Para perder peso, você deve criar um déficit calórico, consumindo menos do que esse total.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Fatores que Influenciam a TMB</h2>
      </header>
      <p class="large">Diversos fatores podem influenciar a TMB, incluindo:</p>
      <p class="large">
        <strong>Idade:</strong> A TMB tende a diminuir com a idade.<br>
        <strong>Sexo:</strong> Homens geralmente têm uma TMB mais alta devido a maior massa muscular.<br>
        <strong>Composição corporal:</strong> Mais massa muscular aumenta a TMB.<br>
        <strong>Níveis hormonais:</strong> Hormônios como a tiroxina podem afetar a TMB.
      </p>
    </section>

    <section class="info-block">
      <header>
        <h2>Dicas Nutricionais</h2>
      </header>
      <p class="large">Para apoiar uma TMB saudável e otimizar a perda de peso, melhorando seu <a
          href="/calculadora-imc">Índice de Massa Corporal (IMB)</a>, é importante seguir algumas diretrizes
        nutricionais:</p>
      <p class="large">
        <strong>Prefira cereais integrais</strong> em vez de refinados.<br>
        <strong>Inclua frutas e verduras</strong> ricas em fibras na sua dieta. São recomendadas cerca de 30g de fibras
        por dia.<br>
        <strong>Proteínas:</strong> Devem compor entre 15 a 25% da sua dieta.<br>
        <strong>Gorduras:</strong> Até 30% da dieta pode ser composta por gorduras saudáveis.
      </p>
    </section>
  </div>

</template>
<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import ProductCard from '@/components/monetization/productCard.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
  name: 'CalculadoraTMB',
  components: {
    CalculatorHeader,
    AppButton,
    BreadCrumb,
    OtherCalculators,
    ProductCard,
    adHorizontal
  },
  data() {
    return {
      breadCrumbItems: [
        { label: 'Calculadora de Taxa Metabólica Basal (TMB)', url: '/calculadora-tmb' }
      ],
      gender: '',
      weight: null,
      height: null,
      age: null,
      tmbResult: null,
    };
  },
  methods: {
    calculateTMB() {
      if (this.gender === 'male') {
        this.tmbResult = (88.36 + (13.4 * this.weight) + (4.8 * this.height) - (5.7 * this.age)).toFixed(2);
      } else {
        this.tmbResult = (447.6 + (9.2 * this.weight) + (3.1 * this.height) - (4.3 * this.age)).toFixed(2);
      }
      this.$nextTick(() => {
        this.scrollToResult();
      });
    },
    scrollToResult() {
      const resultSection = this.$refs.result;
      if (resultSection) {
        resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};
</script>
<style scoped></style>
