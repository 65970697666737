// src/mixins/meta.js
export default {
    watch: {
      '$route' (to) {
        this.updateMeta(to.meta);
      }
    },
    mounted() {
      this.updateMeta(this.$route.meta);
    },
    methods: {
      updateMeta(meta) {
        document.title = meta.title || 'Calculico';
  
        // Update or create description meta tag
        let descriptionTag = document.querySelector('meta[name="description"]');
        if (!descriptionTag) {
          descriptionTag = document.createElement('meta');
          descriptionTag.setAttribute('name', 'description');
          document.head.appendChild(descriptionTag);
        }
        descriptionTag.setAttribute('content', meta.description || 'Calculico - Calculadoras Online');
  
        // Update or create keywords meta tag
        let keywordsTag = document.querySelector('meta[name="keywords"]');
        if (!keywordsTag) {
          keywordsTag = document.createElement('meta');
          keywordsTag.setAttribute('name', 'keywords');
          document.head.appendChild(keywordsTag);
        }
        keywordsTag.setAttribute('content', meta.keywords || 'calculadoras online, planejamento financeiro, saúde, ferramentas de cálculo, calculico');
      }
    }
  };
  