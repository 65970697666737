<template>
    <div>
      <Pie :data="data" :options="options" />
    </div>
  </template>
  
  <script>
  import { Pie } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, ArcElement)
  
  export default {
    name: 'PieChart',
    components: {
      Pie
    },
    props: {
      inss: Number,
      irrf: Number,
      otherDeductions: Number,
      netSalary: Number
    },
    computed: {
      data() {
        return {
          labels: ['INSS', 'IRRF', 'Outros Descontos', 'Salário Líquido'],
          datasets: [
            {
              backgroundColor: ['#FF3355', '#CC0022', '#FF99AA', '#4763EB'],
              data: [this.inss, this.irrf, this.otherDeductions, this.netSalary]
            }
          ]
        }
      },
      options() {
        return {
          responsive: true,
          maintainAspectRatio: false
        }
      },
      myStyles () {
      return {
        position: 'relative',
        font: 'Inter, sans-serif'
      }
    },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  div {
    width: 100%;
    height: auto;
    margin: auto;
  }
  </style>
  