<template>
  <BreadCrumb :items="breadCrumbItems" />
  <div class="wrapper content">
    <adHorizontal />
    <CalculatorHeader emoji="⚖️" title="Calculadora de IMC"
      subtitle="Calcule seu Índice de Massa Corporal, adulto e infantil, de forma rápida e fácil." />
    <div class="calculator-body">
      <form @submit.prevent="calculateIMC">
        <div class="form-section">
          <div class="form-group">
            <label for="weight">Peso (kg):</label>
            <input type="number" v-model="weight" id="weight" placeholder="kg" required />
          </div>
          <div class="form-group">
            <label for="height">Altura (cm):</label>
            <input type="number" v-model="height" id="height" placeholder="cm" required />
            <caption>Informe sua altura em cm. Se você mede 1,70 digite 170.</caption>
          </div>
          <div class="form-group" v-if="imcInfantil">
            <label for="age">Idade (anos):</label>
            <input type="number" v-model="age" id="age" placeholder="anos" required />
            <caption>O IMC infantil considera idades entre 6 e 15 anos.</caption>
          </div>
          <div class="form-group" v-if="imcInfantil">
            <label for="gender">Sexo:</label>
            <select v-model="gender" id="gender" required>
              <option value="" disabled>Selecione</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </select>
            <caption>O cálculo de IMC não tem diferença entre adultos do sexo masculino e feminino.</caption>
          </div>
        </div>
        <div class="form-group">
          <div class="form-checkbox">
            <input type="checkbox" id="imcInfantil" v-model="imcInfantil">
            <div class="label-caption">
              <label for="imcInfantil">IMC Infantil?</label>
              <caption>Selecione caso o cálculo seja sobre alguém entre 6 e 15 anos.</caption>
            </div>
          </div>
        </div>

        <AppButton label="Calcular" type="primary" size="large" />
      </form>
      <div v-if="imcResult" class="results-separator">
        <h4>Resultado</h4>
        <hr />
      </div>
      <div v-if="imcResult !== null" class="result" ref="result">
        <h5>Seu IMC</h5>
        <h3>{{ imcResult }}</h3>
        <p>{{ imcCategory }}</p>
      </div>
    </div>
  </div>
  <!--PRODUCT OFFERS AFFILIATE PROGRAM AMAZON-->
  <div class="category product-shelf">
    <div class="wrapper">
      <div class="category-header">
        <div class="category-title">
          <img src="@/assets/images/icons/gem-solid.svg" width="32" alt="Category Icon" class="category-icon" />
          <h3>Produtos recomendados para monitorar o peso e exercícios</h3>
        </div>
        <p class="large">Selecionamos balanças que ajudam a monitorar peso, IMC e bioimpedância e alguns relógios para
          monitorar exercícios que você pode gostar:</p>
      </div>

      <div class="calculator-cards">
        <ProductCard title="Balança Digital Bioimpedância Relaxmedic com App"
          description="Menos de R$499 | Bem avaliada e completa | Suporta até 180kg | Vidro temperado de alta resistência | 8 sensores de alta precisão | Mede peso, gordura corporal, água corporal, massa muscular, massa óssea, IMC, metabolismo basal e gordura visceral"
          url="https://amzn.to/4bIZzC6" image="https://m.media-amazon.com/images/I/51Ug683HxRL._SX342_.jpg" />
        <ProductCard title="Xiaomi Balança Digital Corporal MI BODY COMPOSITION SCALE 2, Branco"
          description="Melhor custo-benefício, menos de R$200 | Chip BIA de alta precisão controle do percentual de gordura corporal | Teste de equilíbrio | Controle por aplicativo | Design moderno e minimalista"
          url="https://amzn.to/468ZS8g"
          image="https://m.media-amazon.com/images/I/51ytwQsTQJL.__AC_SX300_SY300_QL70_ML2_.jpg" />
        <ProductCard title="Balança Digital de Bioimpedância Com Bluetooth e Aplicativo Analisador Corporal Gordura"
          description="Mais barata, menos de R$100 | Até 180kg | Gordura corporal | Hidratação corporal | Massa Óssea | Massa Muscular | Metabolismo | IMC | Gordura Visceral | Grau de Obesidade"
          url="https://amzn.to/3zJk4kQ" image="https://m.media-amazon.com/images/I/51N4ok21P2L._SX466_.jpg" />
        <!--AMAZON PRIME CARD
        <div class="grid-ad amazon-prime">
          <a href="https://amzn.to/3zJaqhU">
            <img src="@/assets/images/amazon-prime-day3.jpg" alt=""></a>
          <p>Usuários do Calculico tem 30 dias grátis para testar Amazon Prime e aproveitar ofertas e benefícios, como
            entrega grátis e mais rápida.</p>
          <AppButton label="Testar Grátis por 30 dias" type="secondary" href="https://amzn.to/3zJaqhU" />
          <caption>Você será direcionado para a página da Amazon Prime</caption>
        </div>
        AMAZON PRIME CARD END-->
        <ProductCard title="Monitor de atividades e monitoramento de atividades Mi Smart Band 8 Xiaomi"
          description="Menos de R$300 | Carregamento rápido e bateria que dura mais de 16 dias | Tela de 60hz | Monitoramento de oxigenação | Mais de 150 modos fitness para monitorar calorias queimadas | Mais de 200 estilos de mostradores"
          url="https://amzn.to/4f0jOhn" image="https://m.media-amazon.com/images/I/51X6dyFVFpL._AC_SY679_.jpg" />
        <ProductCard title="Balança de Controle Corporal Corpo Inteiro Omron HBF-514C Preto e Prata"
          description="Mais cara, entre R$500 e R$600 | Mais precisa | Armazena até 4 perfis pessoais | Modo Guest (convidado) | Oferece 7 parâmetros corporais | Indica a quantidade de gordura corporal | Até 150kg"
          url="https://amzn.to/3LqGaLy"
          image="https://m.media-amazon.com/images/I/418ZtpU3kwL._SY445_SX342_QL70_ML2_.jpg" />
        <ProductCard title="SAMSUNG Galaxy FIT 3 Tela AMOLED de 1,6 polegadas"
          description="Entre R$300 e R$399 | Mais de 100 modos de exercício | Vida útil da bateria de 14 dias | Mais de 100 mostradores de relógio | Mais de 100 modos de exercício | Modelo internacional - (preto)"
          url="https://amzn.to/3Lmo3q9" image="https://m.media-amazon.com/images/I/41s4LbRyUDL._AC_SX569_.jpg" />
      </div>
      <div class="alert-warning">
        <img src="@/assets/images/icons/exclamation-circle-solid.svg" alt="">
        <p class="small">Os produtos acima são selecionados e recomendados por nós, mas são vendidos e entregues de
          acordo com as informações no site do parceiro, no caso, a Amazon. O Calculico não é responsável pela venda ou
          entrega. Como associado da Amazon, recebemos por compras qualificadas.</p>
      </div>
    </div>
  </div>
  <!--END OF OFFERS AFFILIATE PROGRAM AMAZON-->
  <OtherCalculators :categoryId="3" />
  <div class="calculatorInfo wrapper content">
    <section class="info-block">
      <header>
        <h2>O que é IMC?</h2>
      </header>
      <p class="large">O Índice de Massa Corporal (IMC) é uma medida que usa a altura e o peso para calcular se a pessoa
        está dentro do peso ideal. Ele é amplamente utilizado para identificar se uma pessoa está em uma faixa de peso
        saudável, abaixo do peso ou acima do peso.</p>
    </section>
    <section class="info-block">
      <header>
        <h2>Como Calcular o IMC?</h2>
      </header>
      <p class="large">Para calcular o IMC, divide-se o peso (em quilogramas) pela altura (em metros) elevada ao
        quadrado. A fórmula é: IMC = peso / altura².</p>
    </section>
    <section class="info-block">
      <header>
        <h2>Categorias de IMC</h2>
      </header>
      <table>
        <thead>
          <tr>
            <th>Categoria</th>
            <th>IMC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Abaixo do Peso</td>
            <td>Menos de 18,5</td>
          </tr>
          <tr>
            <td>Peso Normal</td>
            <td>18,5 a 24,9</td>
          </tr>
          <tr>
            <td>Sobrepeso</td>
            <td>25 a 29,9</td>
          </tr>
          <tr>
            <td>Obesidade Grau I</td>
            <td>30 a 34,9</td>
          </tr>
          <tr>
            <td>Obesidade Grau II</td>
            <td>35 a 39,9</td>
          </tr>
          <tr>
            <td>Obesidade Grau III</td>
            <td>40 ou mais</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section class="info-block">
      <header>
        <h2>IMC Infantil</h2>
      </header>
      <p class="large">O Índice de Massa Corporal (IMC) é uma das medidas usadas pela Organização Mundial da Saúde para
        avaliar o estado nutricional, calculado pela fórmula: peso atual (kg) / altura (m)². A classificação para
        adultos é bastante divulgada e está disponível neste site.</p>
      <p class="large">No entanto, a classificação para crianças e adolescentes segue critérios específicos. O site do
        Centers for Disease Control and Prevention (CDC) oferece uma calculadora de IMC para crianças e adolescentes de
        2 a 19 anos de idade, com percentis de IMC-por-idade baseados nos gráficos de crescimento do CDC.</p>
      <p class="large">Esses percentis ajudam a determinar as classificações de baixo peso, peso saudável, sobrepeso e
        obesidade para crianças e adolescentes. Utilize a versão do sistema métrico (métrico) para os cálculos.</p>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Idade</th>
              <th>Abaixo do Normal</th>
              <th>Normal</th>
              <th>Sobrepeso</th>
              <th>Obesidade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="5"><strong>Meninos</strong></td>
            </tr>
            <tr>
              <td>6</td>
              <td>Menor que 14,5</td>
              <td>Entre 14,5 e 16,6</td>
              <td>Entre 16,7 e 18,0</td>
              <td>Maior que 18,0</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Menor que 15,0</td>
              <td>Entre 15,0 e 17,3</td>
              <td>Entre 17,4 e 19,1</td>
              <td>Maior que 19,1</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Menor que 15,6</td>
              <td>Entre 15,6 e 18,0</td>
              <td>Entre 18,1 e 20,3</td>
              <td>Maior que 20,3</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Menor que 16,1</td>
              <td>Entre 16,1 e 18,8</td>
              <td>Entre 18,9 e 21,4</td>
              <td>Maior que 21,4</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Menor que 16,7</td>
              <td>Entre 16,7 e 19,6</td>
              <td>Entre 19,7 e 22,5</td>
              <td>Maior que 22,5</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Menor que 17,2</td>
              <td>Entre 17,2 e 20,3</td>
              <td>Entre 20,4 e 23,7</td>
              <td>Maior que 23,7</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Menor que 17,8</td>
              <td>Entre 17,8 e 21,1</td>
              <td>Entre 21,2 e 24,8</td>
              <td>Maior que 24,8</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Menor que 18,5</td>
              <td>Entre 18,5 e 21,9</td>
              <td>Entre 22,0 e 25,9</td>
              <td>Maior que 25,9</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Menor que 19,2</td>
              <td>Entre 19,2 e 22,7</td>
              <td>Entre 22,8 e 26,9</td>
              <td>Maior que 26,9</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Menor que 19,9</td>
              <td>Entre 19,9 e 23,6</td>
              <td>Entre 23,7 e 27,7</td>
              <td>Maior que 27,7</td>
            </tr>
            <tr>
              <td colspan="5"><strong>Meninas</strong></td>
            </tr>
            <tr>
              <td>6</td>
              <td>Menor que 14,3</td>
              <td>Entre 14,3 e 16,1</td>
              <td>Entre 16,2 e 17,4</td>
              <td>Maior que 17,4</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Menor que 14,9</td>
              <td>Entre 14,9 e 17,1</td>
              <td>Entre 17,2 e 18,9</td>
              <td>Maior que 18,9</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Menor que 15,6</td>
              <td>Entre 15,6 e 18,1</td>
              <td>Entre 18,2 e 20,3</td>
              <td>Maior que 20,3</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Menor que 16,3</td>
              <td>Entre 16,3 e 19,1</td>
              <td>Entre 19,2 e 21,7</td>
              <td>Maior que 21,7</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Menor que 17,0</td>
              <td>Entre 17,0 e 20,1</td>
              <td>Entre 20,2 e 23,2</td>
              <td>Maior que 23,2</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Menor que 17,6</td>
              <td>Entre 17,6 e 21,1</td>
              <td>Entre 21,2 e 24,5</td>
              <td>Maior que 24,5</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Menor que 18,3</td>
              <td>Entre 18,3 e 22,1</td>
              <td>Entre 22,2 e 25,9</td>
              <td>Maior que 25,9</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Menor que 18,9</td>
              <td>Entre 18,9 e 23,0</td>
              <td>Entre 23,1 e 27,7</td>
              <td>Maior que 27,7</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Menor que 19,3</td>
              <td>Entre 19,3 e 23,8</td>
              <td>Entre 23,9 e 27,9</td>
              <td>Maior que 27,9</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Menor que 19,6</td>
              <td>Entre 19,6 e 24,2</td>
              <td>Entre 24,3 e 28,8</td>
              <td>Maior que 28,8</td>
            </tr>
          </tbody>
        </table>
      </div>

    </section>
    <section class="info-block">
      <header>
        <h2>Importância de Manter um IMC Saudável</h2>
      </header>
      <p class="large">Manter um IMC saudável é fundamental para prevenir doenças relacionadas ao peso, como diabetes,
        hipertensão e problemas cardiovasculares. É importante adotar hábitos alimentares saudáveis e praticar
        atividades físicas regularmente.</p>
    </section>
    <section class="info-block">
      <header>
        <h2>Limitações do IMC</h2>
      </header>
      <p class="large">Embora o IMC seja uma ferramenta útil, ele não considera a composição corporal, como a proporção
        de músculo e gordura. Portanto, é importante complementar a avaliação do IMC com outras medidas de saúde, como a
        circunferência abdominal e a avaliação de um profissional de saúde.</p>
    </section>
  </div>

</template>

<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import ProductCard from '@/components/monetization/productCard.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
  name: 'CalculadoraIMC',
  components: {
    CalculatorHeader,
    AppButton,
    BreadCrumb,
    OtherCalculators,
    ProductCard,
    adHorizontal
  },
  data() {
    return {
      breadCrumbItems: [
        { label: 'Calculadora de IMC', url: '/calculadora-imc' }
      ],
      weight: null,
      height: null,
      age: null,
      gender: '',
      imcResult: null,
      imcCategory: '',
      imcInfantil: false,
    };
  },
  methods: {
    calculateIMC() {
      const heightInMeters = this.height / 100;
      this.imcResult = (this.weight / (heightInMeters * heightInMeters)).toFixed(2);

      if (this.age >= 2 && this.age <= 19) {
        this.setIMCChildCategory(this.imcResult, this.age, this.gender);
      } else {
        this.setIMCCategory(this.imcResult);
      }

      this.$nextTick(() => {
        this.scrollToResult();
      });
    },
    setIMCCategory(imc) {
      if (imc < 18.5) {
        this.imcCategory = 'Abaixo do Peso';
      } else if (imc >= 18.5 && imc < 25) {
        this.imcCategory = 'Peso Normal';
      } else if (imc >= 25 && imc < 30) {
        this.imcCategory = 'Sobrepeso';
      } else if (imc >= 30 && imc < 35) {
        this.imcCategory = 'Obesidade Grau I';
      } else if (imc >= 35 && imc < 40) {
        this.imcCategory = 'Obesidade Grau II';
      } else {
        this.imcCategory = 'Obesidade Grau III';
      }
    },
    setIMCChildCategory(imc, age, gender) {
      const boysIMCData = {
        6: { underweight: 14.5, normal: [14.5, 16.6], overweight: [16.7, 18], obesity: 18 },
        7: { underweight: 15, normal: [15, 17.3], overweight: [17.4, 19.1], obesity: 19.1 },
        8: { underweight: 15.6, normal: [15.6, 18], overweight: [18.1, 20.3], obesity: 20.3 },
        9: { underweight: 16.1, normal: [16.1, 18.8], overweight: [18.9, 21.4], obesity: 21.4 },
        10: { underweight: 16.7, normal: [16.7, 19.6], overweight: [19.7, 22.5], obesity: 22.5 },
        11: { underweight: 17.2, normal: [17.2, 20.3], overweight: [20.4, 23.7], obesity: 23.7 },
        12: { underweight: 17.8, normal: [17.8, 21.1], overweight: [21.2, 24.8], obesity: 24.8 },
        13: { underweight: 18.5, normal: [18.5, 21.9], overweight: [22, 25.9], obesity: 25.9 },
        14: { underweight: 19.2, normal: [19.2, 22.7], overweight: [22.8, 26.9], obesity: 26.9 },
        15: { underweight: 19.9, normal: [19.9, 23.6], overweight: [23.7, 27.7], obesity: 27.7 },
      };

      const girlsIMCData = {
        6: { underweight: 14.3, normal: [14.3, 16.1], overweight: [16.2, 17.4], obesity: 17.4 },
        7: { underweight: 14.9, normal: [14.9, 17.1], overweight: [17.2, 18.9], obesity: 18.9 },
        8: { underweight: 15.6, normal: [15.6, 18.1], overweight: [18.2, 20.3], obesity: 20.3 },
        9: { underweight: 16.3, normal: [16.3, 19.1], overweight: [19.2, 21.7], obesity: 21.7 },
        10: { underweight: 17, normal: [17, 20.1], overweight: [20.2, 23.2], obesity: 23.2 },
        11: { underweight: 17.6, normal: [17.6, 21.1], overweight: [21.2, 24.5], obesity: 24.5 },
        12: { underweight: 18.3, normal: [18.3, 22.1], overweight: [22.2, 25.9], obesity: 25.9 },
        13: { underweight: 18.9, normal: [18.9, 23], overweight: [23.1, 27.7], obesity: 27.7 },
        14: { underweight: 19.3, normal: [19.3, 23.8], overweight: [23.9, 27.9], obesity: 27.9 },
        15: { underweight: 19.6, normal: [19.6, 24.2], overweight: [24.3, 28.8], obesity: 28.8 },
      };

      const data = gender === 'male' ? boysIMCData : girlsIMCData;

      if (age in data) {
        const { underweight, normal, overweight, obesity } = data[age];
        if (imc < underweight) {
          this.imcCategory = 'Abaixo do Normal';
        } else if (imc >= normal[0] && imc <= normal[1]) {
          this.imcCategory = 'Normal';
        } else if (imc >= overweight[0] && imc <= overweight[1]) {
          this.imcCategory = 'Sobrepeso';
        } else if (imc > obesity) {
          this.imcCategory = 'Obesidade';
        }
      } else {
        this.imcCategory = 'Dados não disponíveis para esta idade.';
      }
    },
    scrollToResult() {
      const resultSection = this.$refs.result;
      if (resultSection) {
        resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  border-top: 1px solid $neutral-1;
  padding-top: 2rem;
  //background-color: $primary-0;
  border-radius: 8px;
  margin-bottom: -.4rem;
}

.label-caption {
  display: flex;
  flex-direction: column;
}
</style>
