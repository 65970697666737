<template>
  <header class="header">
    <div class="emoji">{{ emoji }}</div>
    <h1 class="title">{{ title }}</h1>
    <p class="large">{{ subtitle }}</p>
  </header>
</template>

<script>
export default {
  name: 'CalculatorHeader',
  props: {
    emoji: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .8rem;
  align-self: stretch;
  text-align: left;
  padding-top: 8rem;
  margin-bottom: 4rem;

  @include bp(mobile) {
    padding-top: 4rem;
    margin-bottom: 1.6rem;
  }

  @include bp(tablet) {
    padding-top: 4rem;
    margin-bottom: 2.4rem;
  }
}

.emoji {
  font-size: 2.4rem;

  @include bp(mobile) {
    font-size: 2rem;
  }
}

.title {
  @extend h2;
}
</style>