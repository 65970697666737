<template>
    <footer class="footer wrapper">
      <div>
        <div class="logo">
          <img src="../../assets/images/brand/calculico-logo-white.svg" width="175" height="40" alt="">
        </div>
        <ul class="mainMenu">
          <li class="menuItem"><a href="../sobre">Sobre Calculico</a></li>
          <!--<li class="menuItem"><a href="../anuncie">Anúncios e Parcerias</a></li>
          <li class="menuItem"><a href="../faq">Perguntas Frequentes</a></li>-->
        </ul>
        <ul class="mainMenu">
          <li class="menuItem"><a href="../contato">Contato</a></li>
        </ul>
        <ul class="mainMenu">
          <li class="menuItem"><a href="/termos">Termos de Uso</a></li>
          <li class="menuItem"><a href="/privacidade">Política de Privacidade</a></li>
        </ul>
      </div>
  
      <div class="multiColumnWrap">
        <ul v-for="category in categories" :key="category.id" class="calcMenu">
          <li>{{ category.name }}</li>
          <li v-for="calculator in category.calculators" :key="calculator.id" class="menuItem">
            <router-link class="menuItem" :to="calculator.pageUrl">{{ calculator.title }}</router-link>
          </li>
        </ul>
      </div>
    </footer>
    <div class="footer-disclaimer wrapper">
      <p class="small"><strong>⚠️ Importante:</strong> As informações e calculadoras disponíveis neste site são destinadas a fins informativos e educativos, com o objetivo de auxiliar os usuários na estimativa de cálculos e na análise de contextos específicos de forma bem informada. Esses dados e cálculos não devem ser considerados oficiais, nem possuem valor legal para a composição de documentos, contratos ou quaisquer instrumentos sujeitos à legislação vigente no Brasil ou em outros países onde possam ser aplicados. Trabalhamos continuamente para revisar e aprimorar nossas fórmulas, códigos e conteúdos, visando fornecer cálculos e informações precisas. No entanto, pequenos erros ou variações podem ocorrer. Utilize estas informações com responsabilidade e consulte um profissional qualificado sempre que necessitar de uma orientação mais completa e adequada à sua situação específica.</p>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  
  export default {
    name: 'appFooter',
    computed: {
      ...mapState(['categories'])
    }
  };
  </script>

<style lang="scss" scoped>
footer.footer {
    display: flex;
    align-items: flex-start;
    gap: 8rem;
    align-self: stretch;
    background: $primary-8;
    padding-top: 8rem;
    padding-bottom: 8rem;

    ul {
        text-decoration: none;
        list-style: none;
        text-align: left;
        padding: 0;
    }

    .logo {
        margin-bottom: 4.8rem;
        padding: 0;
        text-align: left;
    }

    @include bp(mobile) {
        flex-direction: column;
        gap: 4rem;
        padding-top: 6.4rem;
        padding-bottom: 8rem;
    }
}

.multiColumnWrap {
    display: grid;
    font-family: $titlesFont;
    gap: 2.4rem;
    flex-grow: 1;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    ul {
        justify-self: flex-start;
    }
}

.mainMenu {
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    font-family: $titlesFont;

    &:last-of-type {
        border-bottom: 0;
    }

    li {
        margin-bottom: 2.4rem;
    }

    .menuItem a {
        color: white;
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 600;
        text-decoration: none;

        &:hover {
            color: #CCEAFF;
        }
    }
}

.calcMenu ul {
    margin: 0;
}

.calcMenu li {
    margin-bottom: 1.6rem;

    &:first-of-type {
        color: #A3B1F5;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    .menuItem{
        color: white;
        font-size: 1.6rem;
        font-style: normal;
        line-height: 2.4rem;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: #CCEAFF;
        }
    }
}
.footer-disclaimer {
    background: $primary-9;
    color: $primary-1;
    padding-top:8rem;
    padding-bottom: 8rem;

    @include bp(mobile) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    
    .small{
      font-size: 1.2rem;
    }
}
</style>
