<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div class="wrapper content">
        <adHorizontal />
        <CalculatorHeader emoji="🏦" title="Calculadora de FGTS"
            subtitle="Calcule o saldo do FGTS ao final do seu contrato de trabalho considerando os depósitos mensais e os juros anuais." />
            <div class="calculator-body">
            <form @submit.prevent="calculateFGTS">
                <h4>Dados do Contrato de Trabalho</h4>
                <div class="form-section">
                    <div class="form-group">
                        <label for="grossSalary">Salário Bruto:</label>
                        <input type="text" v-model="formattedGrossSalary" @input="onGrossSalaryInput" id="grossSalary"
                            placeholder="R$" required />
                        <caption>Informe o salário bruto mensal</caption>
                    </div>
                    <div class="form-group">
                        <label for="previousBalance">Saldo Anterior do FGTS:</label>
                        <input type="text" v-model="formattedPreviousBalance" @input="onPreviousBalanceInput"
                            id="previousBalance" placeholder="R$" required />
                        <caption>Informe o saldo do FGTS antes do início deste contrato de trabalho</caption>
                    </div>
                    <div class="form-group">
                        <label for="startDate">Data Inicial do Contrato:</label>
                        <input type="date" v-model="startDate" id="startDate" required />
                    </div>
                    <div class="form-group">
                        <label for="endDate">Data Final do Contrato:</label>
                        <input type="date" v-model="endDate" id="endDate" required />
                    </div>
                </div>
                <AppButton label="Calcular" type="primary" size="large" />
            </form>
            <!-- Referência para a inserção do script -->
      <div ref="adsContainer" class="adsterra-container"></div>
            <div v-if="resultsVisible" class="results-separator" ref="result">
                <h4>Resultado</h4>
                <hr />
            </div>
            <div v-if="resultsVisible">
                <div class="result">
                    <h5>Saldo Final do FGTS</h5>
                    <h3>{{ formattedFinalBalance }}</h3>
                </div>
                <div class="detailed-result">
                    <div class="result-box">
                        <div v-if="formattedPreviousBalance!=''" class="label-text">
                            <label>Saldo Anterior</label>
                            <p>{{ staticPreviousBalance }}</p>
                        </div>
                        <div class="label-text">
                            <label>Depósitos Acumulados</label>
                            <p>{{ formattedTotalDeposits }}</p>
                        </div>
                        <div class="label-text">
                            <label>Juros Acumulados</label>
                            <p>{{ formattedTotalInterest }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <OtherCalculators :categoryId="1" />
    <div class="calculatorInfo wrapper content">
    <section class="info-block">
        <header>
            <h2>O que é FGTS?</h2>
        </header>
        <p class="large">O Fundo de Garantia do Tempo de Serviço (FGTS) é um benefício destinado a todos os trabalhadores brasileiros com carteira assinada. Criado com o objetivo de proteger o trabalhador demitido sem justa causa, o FGTS é composto por depósitos mensais realizados pelo empregador, correspondentes a 8% do salário bruto do funcionário.</p>
        <p class="large">Os valores depositados formam uma reserva financeira que pode ser utilizada em diversas situações, como na compra da casa própria, aposentadoria, em casos de doenças graves, entre outros. O FGTS é gerido pela Caixa Econômica Federal, que também é responsável pela aplicação de uma correção anual de 3,3% sobre o saldo.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Como Calcular o FGTS?</h2>
        </header>
        <p class="large">O cálculo do FGTS é simples e direto. Basta multiplicar o valor do salário bruto por 8%. Esse percentual é depositado mensalmente na conta FGTS do trabalhador. Por exemplo, se o seu salário bruto é de R$ 3.000,00, o depósito mensal será de R$ 240,00.</p>
        <p class="large">Além dos depósitos mensais, o saldo do FGTS é corrigido anualmente pela Caixa Econômica Federal, que aplica uma taxa de juros de 3,3% ao ano. Essa correção ajuda a manter o poder de compra do saldo acumulado, protegendo-o contra a inflação.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Importância dos Depósitos Regulares</h2>
        </header>
        <p class="large">Os depósitos mensais no FGTS são fundamentais para garantir uma reserva financeira em situações imprevistas, como demissão sem justa causa, doenças graves ou aposentadoria. É importante acompanhar os depósitos regularmente para garantir que o empregador está cumprindo suas obrigações e que o saldo está correto.</p>
        <p class="large">Manter um saldo saudável no FGTS proporciona segurança financeira e pode ser um importante aliado na realização de sonhos, como a compra da casa própria ou a abertura de um negócio próprio.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Impacto dos Juros Anuais no Saldo do FGTS</h2>
        </header>
        <p class="large">A correção anual de 3,3% aplicada pela Caixa Econômica Federal ao saldo do FGTS é um fator crucial para manter o poder de compra do fundo. Esse rendimento anual compensa, ao menos parcialmente, a perda de valor causada pela inflação, garantindo que o saldo continue a crescer ao longo do tempo.</p>
        <p class="large">Por exemplo, se você tem um saldo de R$ 10.000,00 no FGTS, ao final de um ano, com a aplicação dos juros, o saldo será de R$ 10.330,00. Esse crescimento ajuda a proteger seu fundo contra a desvalorização e a aumentar sua reserva financeira para o futuro.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Exemplo Prático de Cálculo do FGTS</h2>
        </header>
        <p class="large">Para entender melhor como o FGTS é calculado, vamos a um exemplo prático. Suponha que você tenha um salário bruto mensal de R$ 2.500,00 e um saldo anterior de FGTS de R$ 5.000,00. Trabalhando durante um ano, o depósito mensal será de R$ 200,00 (8% de R$ 2.500,00).</p>
        <p class="large">Ao final de 12 meses, os depósitos totalizam R$ 2.400,00. Somando os juros de 3,3% aplicados ao saldo inicial, teremos um saldo final superior a R$ 7.530,00. Este cálculo mostra como os depósitos regulares e os juros anuais contribuem para o crescimento do FGTS.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Dicas para Aproveitar ao Máximo seu FGTS</h2>
        </header>
        <p class="large">Para maximizar os benefícios do seu FGTS, é importante acompanhar regularmente os depósitos e o saldo. Utilize o extrato fornecido pela Caixa Econômica Federal para verificar se todos os depósitos estão sendo realizados corretamente.</p>
        <p class="large">Além disso, o FGTS pode ser utilizado em diversas situações previstas por lei, como na compra da casa própria, em casos de doenças graves, e na aposentadoria. Manter-se informado sobre as regras e possibilidades de uso do FGTS pode ajudar você a tomar decisões financeiras mais acertadas.</p>
    </section>
    <section class="info-block">
        <header>
            <h2>Legislação e Atualizações sobre o FGTS</h2>
        </header>
        <p class="large">A legislação trabalhista e as regras do FGTS estão em constante atualização. É importante estar atento às mudanças para garantir que todos os direitos estão sendo respeitados e que você está aproveitando ao máximo os benefícios oferecidos pelo FGTS.</p>
        <p class="large">Consulte regularmente fontes oficiais, como a Caixa Econômica Federal e o Ministério do Trabalho, para obter informações atualizadas sobre o FGTS. Assim, você pode se assegurar de que está bem informado e preparado para qualquer eventualidade relacionada ao seu fundo de garantia.</p>
    </section>
</div>


</template>

<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';


export default {
    name: 'CalculadoraFGTS',
    components: {
        CalculatorHeader,
        AppButton,
        BreadCrumb,
        OtherCalculators,
        adHorizontal
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Calculadora de FGTS', url: '/calculadora-fgts' }
            ],
            grossSalary: 0,
            formattedGrossSalary: '',
            previousBalance: 0,
            formattedPreviousBalance: '',
            staticPreviousBalance:'',
            startDate: '',
            endDate: '',
            resultsVisible: false,
            finalBalance: 0,
            formattedFinalBalance: '',
            totalDeposits: 0,
            formattedTotalDeposits: '',
            totalInterest: 0,
            formattedTotalInterest: ''
        };
    },
    methods: {
        calculateFGTS() {
            const depositRate = 0.08;
            const annualInterestRate = 0.033;
            const monthlyInterestRate = Math.pow(1 + annualInterestRate, 1 / 12) - 1;

            let currentBalance = this.unformatCurrency(this.formattedPreviousBalance);
            const monthlyDeposit = this.unformatCurrency(this.formattedGrossSalary) * depositRate;

            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);
            const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

            let totalDeposits = 0;
            let totalInterest = 0;

            for (let month = 1; month <= months; month++) {
                currentBalance += monthlyDeposit;
                totalDeposits += monthlyDeposit;

                let interest = currentBalance * monthlyInterestRate;
                currentBalance += interest;
                totalInterest += interest;
            }

            this.finalBalance = currentBalance;
            this.totalDeposits = totalDeposits;
            this.totalInterest = totalInterest;

            this.formattedFinalBalance = this.formatCurrency(this.finalBalance * 100);
            this.formattedTotalDeposits = this.formatCurrency(this.totalDeposits * 100);
            this.formattedTotalInterest = this.formatCurrency(this.totalInterest * 100);
            this.staticPreviousBalance = this.formattedPreviousBalance;

            this.resultsVisible = true;
            this.scrollToResult();
        },
        onGrossSalaryInput(event) {
            const value = event.target.value.replace(/\D/g, '');
            this.formattedGrossSalary = this.formatCurrency(value);
        },
        onPreviousBalanceInput(event) {
            const value = event.target.value.replace(/\D/g, '');
            this.formattedPreviousBalance = this.formatCurrency(value);
        },
        formatCurrency(value, noCurrencySymbol = false) {
            if (!noCurrencySymbol) {
                let val = (value / 100).toFixed(2).replace('.', ',');
                return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                let val = (value / 100).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        },
        unformatCurrency(value) {
            return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
        },
        scrollToResult() {
            this.$nextTick(() => {
                const resultSection = this.$refs.result;
                if (resultSection) {
                    resultSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.results-table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.results-table {
    border-collapse: collapse;
    margin-top: 2rem;
    border: 1px solid #ddd;
    border-radius: 20px;

    th,
    td {
        padding: 1rem;
        text-align: right;
        border-bottom: 1px solid #ddd;
    }

    tr {
        &:nth-child(even) {
            background-color: #f9f9f9;
        }

        &:last-of-type {
            td {
                border-bottom: 0;
            }
        }

        &:hover {
            background-color: #f1f1f1;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        }
    }

    th {
        background-color: #f1f1f1;
        padding: 1.2rem;
    }

    input {
        padding: 12px;
        max-width: 150px;
    }
}

.final-value {
    color: #007bff;
    font-weight: 600;
}
</style>
