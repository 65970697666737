<template>
  <div :class="cardClasses">
    <span class="emoji">{{ emoji }}</span>
    <h4 class="title">{{ title }}</h4>
    <p class="description">{{ description }}</p>
    <AppButton :label="'Calcular Agora'" :to="pageUrl" />
  </div>
</template>

<script>
import AppButton from '@/components/common/appButton.vue';

export default {
  name: 'CalculatorCard',
  components: {
    AppButton
  },
  props: {
    emoji: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    pageUrl: {
      type: String,
      required: true
    },
    bgStyle: {
      type: String,
      default: '',
      validator: value => ['', 'white'].includes(value)
    }
  },
  computed: {
    cardClasses() {
      return [
        'card',
        this.bgStyle ? `card--${this.bgStyle}` : ''
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $primary-0;
  border-radius: 2.4rem;
  border: 4px solid $primary-0;
  padding: 3.2rem;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  gap: .8rem;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 4px solid $primary-1;
    background-color: $white;
  }

  @include bp(mobile) {
    padding: 2rem;
  }
}

.card--white {
  background-color: $white;
  border-radius: 2.4rem;
  border: 2px solid $primary-1;
  padding: 3.2rem;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  box-sizing: content-box;
  transition: all 0.3s ease-in-out;
  gap: .8rem;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid $primary-3;
    background-color: $white;
  }

  @include bp(mobile) {
    padding: 2rem;
  }
}

.emoji {
  font-size: 2.4rem;
  margin: 0;
}

.title {
  color: $primary-8;
}

.description {
  margin-bottom: 1.6rem;
  flex-grow: 1;

  @include bp(mobile) {
    margin-bottom: .8rem;
  }
}
</style>
