<template>
    <div class="product-card">
        <img :src="image" alt="Product Image" />
        <div class="title-paragraph">
        <h6>{{ title }}</h6>
        <p>{{ description }}</p>
    </div>
        <AppButton :href="url" size="medium" label="Ver Preços" type="outline" class="product-card-button"/>
        <caption>Você será direcionado para a página da <strong>Amazon</strong></caption>
        
        
    </div>
</template>

<script>
import AppButton from '@/components/common/appButton.vue';

export default {
    name: 'ProductCard',
    components: {
        AppButton
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.product-card {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1 0 0;
    border: 1px solid $neutral-2;
    border-radius: 16px;
    background-color: $white;

    &:hover {
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
        border-color: $warning-6    }


    p{
        @extend p, .small;
    }
    h6{
        @extend h6;
    }
    img {
        width: auto;
        height: 160px;
        object-fit: contain;
        border-radius: 16px;
    }
    .title-paragraph {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        flex-grow: 1;
    }

    caption{
        @extend caption;
        font-size: 1.2rem;
        text-align: center;

        strong {
            font-weight: 500;
        }
    }
}
</style>