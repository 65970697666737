<template>
  <div id="app">
    <appHeader @updateSearchQuery="updateSearchQuery" :internalNavbar="internalNavbar" />
    <router-view></router-view>
    <appFooter />
  </div>
</template>

<script>
import appHeader from './components/layout/appHeader.vue';
import appFooter from './components/layout/appFooter.vue';
import MetaMixin from './mixins/meta';

export default {
  name: 'App',
  title: 'Calculico',
  mixins: [MetaMixin],
  components: {
    appHeader,
    appFooter
  },
  watch:{
    $route(to){
      if (to.path === '/') {
        this.internalNavbar = false;
      } else {
        this.internalNavbar = true;
      }
    },
  },
  data() {
    return {
      internalNavbar: false,
    };
  },

  mounted() {
    console.log(this.$route.path);
    console.log(this.internalNavbar);
    if (this.$route.path === '/sobre') {
      console.log('internalNavbar');
      this.internalNavbar = true;
    } else {
      this.internalNavbar = false;
    }
  }

};
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

</style>
