<template>
  <BreadCrumb :items="breadCrumbItems" />
  <adHorizontal />
  <div class="wrapper content">
    <CalculatorHeader emoji="⏰" title="Calculadora de Horas Extras CLT"
      subtitle="Calcule o valor das suas horas extras com base no seu salário e no percentual adicional." />
    <div class="calculator-body">
      <form @submit.prevent="calculateOvertime">
        <h4>Informações para Cálculo</h4>
        <div class="form-section">
          <div class="form-group">
            <label for="grossSalary">Salário Bruto:</label>
            <input type="text" v-model="formattedGrossSalary" @input="onGrossSalaryInput" id="grossSalary" placeholder="R$" required />
            <caption>Valor total do salário antes de qualquer desconto.</caption>
          </div>
          <div class="form-group">
            <label for="hoursWorked">Jornada de Horas no Mês:</label>
            <input type="number" v-model="hoursWorked" id="hoursWorked" required />
            <caption>Se não souber, basta multiplicar a quantidade de horas trabalhadas na semana por 5. Por exemplo: 44h semanais equivalem a 220h mensais.</caption>
          </div>
          <div class="form-group">
            <label for="overtimeNormal">Horas Extras Normais:</label>
            <input type="number" v-model="overtimeNormal" id="overtimeNormal" required />
            <caption>Horas extras trabalhadas até às 22h.</caption>
          </div>
          <div class="form-group">
            <label for="overtimeNight">Horas Extras Noturnas:</label>
            <input type="number" v-model="overtimeNight" id="overtimeNight" required />
            <caption>Horas extras trabalhadas entre 22h e 6h do dia seguinte.</caption>
          </div>
          <div class="form-group">
            <label for="overtime100">Horas Extras 100%:</label>
            <input type="number" v-model="overtime100" id="overtime100" required />
            <caption>Horas extras trabalhadas em domingos ou feriados.</caption>
          </div>
        </div>
        <AppButton label="Calcular" type="primary" size="large" />
      </form>
      <div v-if="resultsVisible" class="results-separator">
        <h4>Resultado</h4>
        <hr />
      </div>
      <div v-if="resultsVisible" ref="result">
        <div class="result">
          <h5>Valor Bruto das Horas Extras</h5>
          <h3>{{ formattedOvertimePay }}</h3>
        </div>
        <div class="detailed-result">
          <div class="result-box">
            <div class="label-text">
              <label>Salário Base (Bruto)</label>
              <p>{{ formattedGrossSalary }}</p>
            </div>
            <div class="label-text">
              <label>Total de Horas Extras Normais</label>
              <p>{{ formattedOvertimePayNormal }}</p>
            </div>
            <div class="label-text">
              <label>Total de Horas Noturnas</label>
              <p>{{ formattedOvertimePayNight }}</p>
            </div>
            <div class="label-text">
              <label>Total de Horas Extras 100%</label>
              <p>{{ formattedOvertimePay100 }}</p>
            </div>
            <div class="label-text">
              <label>Salario Bruto Total com Horas Extras</label>
              <p>{{ formattedTotalPayAfterOvertime }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OtherCalculators :categoryId="1" />
  <div class="calculatorInfo wrapper content">
    <section class="info-block">
      <header>
        <h2>O Que São Horas Extras?</h2>
      </header>
      <p class="large">Horas extras são todas as horas trabalhadas além da jornada normal de trabalho, que geralmente é de 8 horas diárias ou 44 horas semanais, conforme determinado pela Consolidação das Leis do Trabalho (CLT). Elas devem ser remuneradas com um acréscimo de no mínimo 50% sobre o valor da hora normal de trabalho.</p>
    </section>
    <section class="info-block">
  <header>
    <h2>Conceito Legal</h2>
  </header>
  <p class="large">De acordo com o Artigo 59 da CLT, as horas que excedem a jornada normal de trabalho serão consideradas horas extraordinárias e devem ser remuneradas com um acréscimo de, no mínimo, 50%. Esse adicional pode variar dependendo das convenções coletivas de cada categoria profissional.</p>
  <p class="large">As horas extras integram o aviso prévio indenizado e têm reflexos no Descanso Semanal Remunerado (DSR). Elas também fazem parte do cálculo de outras verbas trabalhistas como 13º salário, FGTS, aviso prévio indenizado, férias e suas respectivas remunerações.</p>
</section>

<section class="info-block">
  <header>
    <h2>Como Calcular Hora Extra?</h2>
  </header>
  <p class="large">Para calcular o valor das horas extras, siga os passos abaixo:</p>
  <ul>
    <li>Divida o valor do salário bruto pelo número de horas trabalhadas no mês. Por exemplo: R$ 5000 / 220 horas = R$ 22,73 por hora.</li>
    <li>Multiplique o valor da hora pelo percentual adicional. Para 50%: R$ 22,73 * 0.5 = R$ 11,37.</li>
    <li>Some o valor da hora normal ao valor adicional. Exemplo: R$ 22,73 + R$ 11,37 = R$ 34,10 por hora extra.</li>
  </ul>
</section>

<section class="info-block">
  <header>
    <h2>Horas Extras Noturnas</h2>
  </header>
  <p class="large">As horas extras noturnas, realizadas entre 22h e 5h, recebem um adicional de 20% sobre o valor da hora diurna. Esse valor adicional é somado ao percentual de 50% para as horas extras, resultando em um aumento total de 70% sobre a hora normal.</p>
  <p class="large">Exemplo de cálculo: R$ 22,73 (hora normal) + R$ 4,55 (adicional noturno) + R$ 11,37 (adicional de 50%) = R$ 38,65 por hora extra noturna.</p>
</section>

<section class="info-block">
  <header>
    <h2>Horas Extras 100%</h2>
  </header>
  <p class="large">Horas extras trabalhadas em domingos e feriados são remuneradas com um adicional de 100% sobre o valor da hora normal. Isso significa que o trabalhador recebe o dobro do valor da hora normal por cada hora extra trabalhada nesses dias.</p>
  <p class="large">Exemplo de cálculo: R$ 22,73 (hora normal) + R$ 22,73 (adicional de 100%) = R$ 45,46 por hora extra.</p>
</section>

<section class="info-block">
  <header>
    <h2>Banco de Horas</h2>
  </header>
  <p class="large">O banco de horas é uma modalidade que permite a compensação de horas extras trabalhadas por folgas ou diminuição da jornada em outros dias. Com a reforma trabalhista de 2017, o banco de horas pode ser negociado diretamente com o empregador e compensado em até seis meses, ou em até um ano, se negociado com o sindicato.</p>
  <p class="large">Artigo 59, § 5º da CLT: "O banco de horas poderá ser pactuado por acordo individual escrito, desde que a compensação ocorra no período máximo de seis meses."</p>
</section>

<section class="info-block">
  <header>
    <h2>Horas Extras Ilícitas</h2>
  </header>
  <p class="large">Horas extras ilícitas são aquelas prestadas em desacordo com as normas legais, como exceder o limite de 2 horas diárias ou não comunicar a realização das horas extras à Delegacia Regional do Trabalho. Em situações de necessidade imperiosa, força maior ou serviços inadiáveis, as horas extras podem ser realizadas além do limite legal, mas com uma remuneração diferenciada.</p>
  <p class="large">Artigo 61 da CLT: "Ocorrendo necessidade imperiosa, poderá a duração do trabalho exceder do limite legal ou convencionado, seja para fazer face a motivo de força maior, seja para atender à realização ou conclusão de serviços inadiáveis ou cuja inexecução possa acarretar prejuízo manifesto."</p>
</section>

<section class="info-block">
  <header>
    <h2>Reforma Trabalhista e Horas Extras</h2>
  </header>
  <p class="large">A reforma trabalhista de 2017 trouxe mudanças significativas na compensação de horas extras. Agora, é possível negociar o banco de horas diretamente com o empregador, com compensação em até seis meses. A negociação com o sindicato pode estender esse prazo para um ano. A compensação individual deve ser realizada dentro do mesmo mês.</p>
</section>

<section class="info-block">
  <header>
    <h2>Jornada 12x36</h2>
  </header>
  <p class="large">A jornada de trabalho 12x36 permite que o trabalhador cumpra 12 horas de trabalho seguidas por 36 horas de descanso. Essa modalidade de jornada deve ser acordada por convenção coletiva ou acordo coletivo de trabalho.</p>
  <p class="large">Artigo 59-A da CLT: "Em exceção ao disposto no art. 59 e em leis específicas, é facultado às partes, por meio de convenção coletiva ou acordo coletivo de trabalho, estabelecer horário de trabalho de doze horas seguidas por trinta e seis horas ininterruptas de descanso."</p>
</section>

<section class="info-block">
  <header>
    <h2>Resumo</h2>
  </header>
  <p class="large">Horas extras são uma forma legal de estender a jornada de trabalho, mas devem ser remuneradas adequadamente e respeitar os limites estabelecidos pela CLT. A correta compreensão e cálculo das horas extras são essenciais para garantir os direitos dos trabalhadores e a conformidade das empresas com a legislação trabalhista.</p>
</section>
</div>
</template>

<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
  name: 'CalculadoraHorasExtrasCLT',
  components: {
    CalculatorHeader,
    AppButton,
    BreadCrumb,
    OtherCalculators,
    adHorizontal
  },
  data() {
    return {
      breadCrumbItems: [
        { label: 'Calculadora de Horas Extras CLT', url: '/calculadora-horas-extras-clt' }
      ],
      grossSalary: 0,
      formattedGrossSalary: '',
      hoursWorked: 220,
      overtimeNormal: 0,
      overtimeNight: 0,
      overtime100: 0,
      hourlyRate: 0,
      additionalRate50: 0,
      additionalRate100: 0,
      overtimeRateNormal: 0,
      overtimeRateNight: 0,
      overtimeRate100: 0,
      totalOvertimeHours: 0,
      overtimePay: 0,
      overtimePayNormal: 0,
      overtimePayNight: 0,
      overtimePay100: 0,
      formattedHourlyRate: '',
      formattedAdditionalRate50: '',
      formattedAdditionalRate100: '',
      formattedOvertimeRateNormal: '',
      formattedOvertimeRateNight: '',
      formattedOvertimeRate100: '',
      formattedOvertimePay: '',
      formattedOvertimePayNormal: '',
      formattedOvertimePayNight: '',
      formattedOvertimePay100: '',
      resultsVisible: false
    };
  },
  methods: {
    calculateOvertime() {
      const grossSalaryNumber = this.unformatCurrency(this.formattedGrossSalary);
      const hourlyRate = grossSalaryNumber / this.hoursWorked;
      const additionalRate50 = hourlyRate * 0.5;
      const additionalRate100 = hourlyRate;
      const overtimeRateNormal = hourlyRate + additionalRate50;
      const overtimeRateNight = (hourlyRate * 1.2) + additionalRate50;
      const overtimeRate100 = hourlyRate + additionalRate100;

      const overtimePayNormal = overtimeRateNormal * this.overtimeNormal;
      const overtimePayNight = overtimeRateNight * this.overtimeNight;
      const overtimePay100 = overtimeRate100 * this.overtime100;
      const overtimePay = overtimePayNormal + overtimePayNight + overtimePay100;

      this.hourlyRate = hourlyRate.toFixed(2);
      this.additionalRate50 = additionalRate50.toFixed(2);
      this.additionalRate100 = additionalRate100.toFixed(2);
      this.overtimeRateNormal = overtimeRateNormal.toFixed(2);
      this.overtimeRateNight = overtimeRateNight.toFixed(2);
      this.overtimeRate100 = overtimeRate100.toFixed(2);
      this.overtimePayNormal = overtimePayNormal.toFixed(2);
      this.overtimePayNight = overtimePayNight.toFixed(2);
      this.overtimePay100 = overtimePay100.toFixed(2);
      this.overtimePay = overtimePay.toFixed(2);
      this.totalOvertimeHours = this.overtimeNormal + this.overtimeNight + this.overtime100;
      this.totalPayAfterOvertime = grossSalaryNumber + overtimePay;

      this.formattedHourlyRate = this.formatCurrency(this.hourlyRate * 100);
      this.formattedAdditionalRate50 = this.formatCurrency(this.additionalRate50 * 100);
      this.formattedAdditionalRate100 = this.formatCurrency(this.additionalRate100 * 100);
      this.formattedOvertimeRateNormal = this.formatCurrency(this.overtimeRateNormal * 100);
      this.formattedOvertimeRateNight = this.formatCurrency(this.overtimeRateNight * 100);
      this.formattedOvertimeRate100 = this.formatCurrency(this.overtimeRate100 * 100);
      this.formattedOvertimePayNormal = this.formatCurrency(this.overtimePayNormal * 100);
      this.formattedOvertimePayNight = this.formatCurrency(this.overtimePayNight * 100);
      this.formattedOvertimePay100 = this.formatCurrency(this.overtimePay100 * 100);
      this.formattedOvertimePay = this.formatCurrency(this.overtimePay * 100);
      this.formattedTotalPayAfterOvertime = this.formatCurrency(this.totalPayAfterOvertime * 100);

      this.resultsVisible = true;
      this.scrollToResult();
    },
    onGrossSalaryInput(event) {
      const value = event.target.value.replace(/\D/g, '');
      this.formattedGrossSalary = this.formatCurrency(value);
    },
    formatCurrency(value) {
      let val = (value / 100).toFixed(2).replace('.', ',');
      return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    unformatCurrency(value) {
      return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
    },
    scrollToResult() {
      this.$nextTick(() => {
        const resultSection = this.$refs.result;
        if (resultSection) {
          resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
