<template>
    <div class="empty-search">
        <img src="../../assets/images/icons/meh-solid.svg" width="32" alt="">
        <h4>Nenhuma calculadora encontrada</h4>
        <p>Desculpe, não encontramos nenhuma calculadora ao procurar por "<strong>{{query}}</strong>"".</p>
    </div>
</template>

<script>
export default {
    name: 'SearchEmpty',
    props: {
        query: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.empty-search {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 4.8rem 4.8rem 6.4rem 4.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    border-radius: var(--4, 24px);
    border: 1px dashed var(--Neutral-1,#EEEDEC);
    background: var(--Neutral-05, #FAFAFA);
    box-shadow: 0px 0px 0px 8px #FFF, 0px 12px 16px -8px rgba(0, 0, 0, 0.10), 0px 0px 98px 8px #FAFAFA;
}
h4{
    color: var(--Neutral-9, #1B1A18);
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

p{
    color: var(--Neutral-8, #3B3A39);
    font-size: 1.6rem;
    font-family: Inter, sans-serif;
}
</style>