<template>
  <BreadCrumb :items="breadCrumbItems" />
  <div class="wrapper content">
    <adHorizontal />
    <CalculatorHeader emoji="🤑" title="Calculadora de Salário Líquido CLT"
      subtitle="Calcule o valor exato que você recebe após descontos como impostos e contribuições. Facilite seu planejamento financeiro." />
    <div class="calculator-body">
      <form @submit.prevent="calculateNetSalary">
        <h4>Salário e Descontos</h4>
        <div class="form-section">
          <div class="form-group">
            <label for="grossSalary">Salário Bruto:</label>
            <input type="text" v-model="formattedGrossSalary" @input="onGrossSalaryInput" id="grossSalary"
              placeholder="R$" required />
            <caption>Valor total do salário antes de qualquer desconto.</caption>
          </div>
          <div class="form-group">
            <label for="dependents">Número de Dependentes:</label>
            <input type="number" v-model="dependents" id="dependents" required />
            <caption>Número de dependentes, considerado para cálculos de benefícios como o Imposto de Renda.</caption>
          </div>
          <div class="form-group">
            <label for="benefits">Benefícios:</label>
            <input type="text" v-model="formattedBenefits" @input="onBenefitsInput" id="benefits" placeholder="R$" />
            <caption>Valores dados como benefício, além do salário, como vale transporte, alimentação, refeição ou plano
              de saúde</caption>
          </div>
          <div class="form-group">
            <label for="otherDeductions">Outros Descontos:</label>
            <input type="text" v-model="formattedOtherDeductions" @input="onOtherDeductionsInput" id="otherDeductions"
              placeholder="R$" />
            <caption>Descontos legais ou acordados com a empresa</caption>
          </div>
        </div>
        <AppButton label="Calcular" type="primary" size="large" />
      </form>
      <!-- <adSquare /> -->
      <div v-if="resultsVisible" class="results-separator">
        <h4>Resultado</h4>
        <hr />
      </div>
      <div v-if="resultsVisible" ref="result">
        <div class="result">
          <h5>Salário Líquido</h5>
          <h3>{{ netSalary }}</h3>
        </div>
        <div class="detailed-result">
          <div class="result-box">
            <div class="label-text">
              <label>Proventos</label>
              <p>{{ formattedProventos }}</p>
            </div>
            <div class="label-text">
              <label>Salário Bruto</label>
              <p>{{ staticGrossSalary }}</p>
            </div>
            <div class="label-text">
              <label>Benefícios</label>
              <p>{{ staticBenefits }}</p>
            </div>
          </div>
          <div class="result-box">
            <div class="label-text">
              <label>Descontos</label>
              <p>{{ formattedTotalDescontos }}</p>
            </div>
            <div class="label-text">
              <label>INSS</label>
              <p>{{ formattedINSS }}</p>
            </div>
            <div class="label-text">
              <label>IRRF</label>
              <p>{{ formattedIRRF }}</p>
            </div>
            <div class="label-text">
              <label>Outros Descontos</label>
              <p>{{ staticOtherDeductions }}</p>
            </div>
          </div>
          <div class="result-box">
            <PieChart :inss="unformatCurrency(formattedINSS)" :irrf="unformatCurrency(formattedIRRF)"
              :otherDeductions="unformatCurrency(formattedOtherDeductions)" :netSalary="unformatCurrency(netSalary)" />

          </div>
        </div>
      </div>
    </div>
  </div>
  <OtherCalculators :categoryId="1" />
  <div class="calculatorInfo wrapper content">
    <section class="info-block">
      <header>
        <h2>O que é salário líquido?</h2>
      </header>
      <p class="large">O salário líquido é o valor que você realmente recebe após todos os descontos obrigatórios e
        adicionais serem aplicados ao seu salário bruto. Isso inclui descontos como INSS, IRRF, contribuições sindicais,
        plano de saúde, vale-transporte, entre outros. Para quem está inserido no regime CLT, entender como esses
        descontos impactam o valor final do salário em 2024 é fundamental para um bom planejamento financeiro.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Por que as empresas informam o salário bruto e não o salário líquido?</h2>
      </header>
      <p class="large">As empresas geralmente informam o salário bruto ao invés do salário líquido por algumas razões
        essenciais.</p>
      <p class="large">Primeiro, o salário bruto reflete o valor total que a empresa está disposta a pagar pelo serviço
        do funcionário, antes de quaisquer deduções. Esse valor inclui todos os componentes da remuneração, como o
        salário-base, bônus e gratificações.</p>
      <p class="large">Deduções obrigatórias, como o INSS (Instituto Nacional do Seguro Social) e o IRRF (Imposto de
        Renda Retido na Fonte), variam de acordo com a faixa salarial e a situação pessoal do funcionário (por exemplo,
        número de dependentes). Essas deduções são consideradas, respectivamente, como uma contribuição para a
        aposentadoria e uma obrigação fiscal.</p>
      <p class="large">Informar o salário bruto também traz maior transparência e comparabilidade. Permite que os
        candidatos a uma vaga comparem ofertas de emprego de diferentes empresas de forma mais direta.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Em resumo:</h3>
      </header>
      <ul>
        <li><strong>Valorização total da remuneração:</strong> Mostra tudo o que o empregador está disposto a pagar.
        </li>
        <li><strong>Variações de deduções:</strong> Dependem de fatores pessoais e fiscais.</li>
        <li><strong>Transparência e comparabilidade:</strong> Facilita a comparação entre diferentes propostas de
          emprego.</li>
      </ul>
      <p class="large">Entender esses pontos ajuda a clarear por que o salário bruto é informado, oferecendo uma visão
        completa da remuneração antes das deduções.</p>
    </section>


    <section class="info-block">
      <header>
        <h2>Como utilizar a calculadora de salário líquido?</h2>
      </header>
      <ul>
        <li><strong>Preencha o valor do seu salário bruto:</strong> Na calculadora, preencha o valor do seu salário
          bruto, aquele informado na oferta da vaga de emprego ou que a área de Recursos Humanos tenha informado.</li>
        <li><strong>Informe o número de dependentes:</strong> O número de dependentes influencia no cálculo do Imposto
          de Renda retido na fonte. Indique seus dependentes legais conforme consta na sua Declaração Anual do Imposto
          de Renda. Por exemplo, em uma família onde o pai e a mãe de dois filhos fazem o IRPF como contribuintes, as
          crianças devem ser declaradas como dependentes apenas uma vez (um para cada responsável, os dois com um dos
          pais, etc).</li>
        <li><strong>Indique a existência de outros descontos:</strong> Nossa calculadora já traz os descontos
          obrigatórios (INSS e IRRF), então, no campo para outros descontos basta inserir valores extras, como
          previdência privada, assistência médica, entre outros.</li>
        <li><strong>Solicite o cálculo:</strong> Para concluir o processo, basta clicar em calcular e a ferramenta vai
          trazer todos os dados devidamente discriminados.</li>
      </ul>
      <p class="large">Utilizar uma ferramenta precisa para calcular o salário líquido não só ajuda a entender melhor o
        impacto dos descontos, mas também é essencial para um planejamento financeiro eficaz.</p>
    </section>



    <section class="info-block">
      <header>
        <h2>Como Calcular o Salário Líquido?</h2>
      </header>
      <p class="large">Para calcular o salário líquido, subtrai-se do salário bruto todos os descontos obrigatórios e
        adicionais. Entre os principais descontos estão o INSS, que é a contribuição para a previdência social, e o
        IRRF, que é o imposto de renda retido na fonte. Além disso, podem haver outros descontos como plano de saúde,
        vale-transporte e contribuições sindicais. Em 2024, essas alíquotas podem variar, por isso é importante utilizar
        uma calculadora atualizada.</p>
      <p class="large">No entanto, para chegar ao valor exato do salário líquido, é útil utilizar uma calculadora de
        salário líquido confiável. Essas ferramentas online facilitam o cálculo, garantindo precisão e economia de
        tempo. Além dos descontos principais, elas consideram variações regionais e atualizações das alíquotas,
        proporcionando um resultado mais preciso.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Passos para Calcular o Salário Líquido</h3>
      </header>
      <ul>
        <li><strong>Identifique o Salário Bruto:</strong> O valor total acordado antes de qualquer desconto.</li>
        <li><strong>Subtraia os Descontos Obrigatórios:</strong>
          <ul>
            <li><strong>INSS:</strong> Contribuição para a previdência social.</li>
            <li><strong>IRRF:</strong> Imposto de renda retido na fonte.</li>
          </ul>
        </li>
        <li><strong>Considere Outros Descontos:</strong>
          <ul>
            <li>Plano de saúde.</li>
            <li>Vale-transporte.</li>
            <li>Contribuições sindicais.</li>
          </ul>
        </li>
      </ul>
      <p class="large">Utilizar uma calculadora de salário líquido é especialmente útil para garantir que todos os
        detalhes sejam considerados, desde as alíquotas mais recentes até descontos adicionais específicos. Assim, você
        obtém uma visão clara e precisa do seu salário líquido, ajudando no planejamento financeiro pessoal.</p>
    </section>


    <section class="info-block">
      <header>
        <h2>Os descontos obrigatórios incidem nos adicionais trabalhistas?</h2>
      </header>
      <p class="large">Os adicionais trabalhistas, como horas extras, adicional de insalubridade ou periculosidade, têm
        um papel fundamental na complementação da remuneração do trabalhador. No entanto, na maioria dos casos, esses
        valores não são considerados parte integrante do salário bruto regular do empregado.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Tratamento Fiscal dos Adicionais</h3>
      </header>
      <p class="large"><strong>INSS (Instituto Nacional do Seguro Social):</strong> Os adicionais trabalhistas não
        sofrem descontos destinados ao INSS, pois não compõem o salário base sobre o qual a contribuição é calculada.
      </p>
      <p class="large"><strong>IRRF (Imposto de Renda Retido na Fonte):</strong> Da mesma forma, os valores adicionais
        não são tributados pelo IRRF, uma vez que não fazem parte do conjunto de rendimentos regulares usados para esta
        cobrança.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Implicações para o Empregado</h3>
      </header>
      <p class="large">Desse modo, os trabalhadores podem receber adicionais sem a preocupação de reduções
        significativas decorrentes das obrigações fiscais habituais. Esta regulamentação visa assegurar que bonificações
        específicas cumpram o objetivo de complementar a remuneração de forma íntegra e justa.</p>
      <p class="large">Ao entender que os adicionais trabalhistas são excluídos das bases de cálculo para INSS e IRRF, o
        empregado pode planejar suas finanças pessoais com mais precisão e segurança, aproveitando plenamente os
        benefícios desses adicionais.</p>
    </section>


    <section class="info-block">
      <header>
        <h2>Diferença Entre Adicionais Trabalhistas e Variáveis Salariais</h2>
      </header>
      <p class="large">Entender a diferença entre adicionais trabalhistas e variáveis salariais é essencial para
        compreender como cada tipo de remuneração afeta o seu bolso e o seu trabalho.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Adicionais Trabalhistas</h3>
      </header>
      <p class="large">Adicionais trabalhistas são pagamentos adicionais que o empregado recebe devido a condições
        especiais de trabalho. Esses valores são compensatórios e estão ligados ao desgaste físico e mental causado
        pelas atividades. Alguns exemplos incluem:</p>
      <ul>
        <li><strong>Adicional Noturno:</strong> Pago para quem trabalha em períodos noturnos, como forma de compensar o
          impacto no relógio biológico.</li>
        <li><strong>Adicional de Insalubridade:</strong> Relacionado ao trabalho em ambientes que podem trazer riscos à
          saúde.</li>
        <li><strong>Adicional de Periculosidade:</strong> Destinado a quem exerce funções perigosas, como eletricistas e
          vigilantes.</li>
        <li><strong>Hora Extra:</strong> Corresponde ao tempo trabalhado além da jornada habitual, recompensando o
          esforço adicional do trabalhador.</li>
      </ul>
      <p class="large">Esses adicionais são regulamentados pela legislação trabalhista e visam proteger e compensar os
        empregados por circunstâncias que ultrapassam a rotina comum de trabalho.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Variáveis Salariais</h3>
      </header>
      <p class="large">Já as variáveis salariais estão diretamente ligadas ao desempenho do empregado e têm um caráter
        motivacional. Estes valores não são fixos e podem variar conforme a produtividade e os resultados alcançados.
        Exemplos de variáveis salariais incluem:</p>
      <ul>
        <li><strong>Comissões:</strong> Pagas com base no volume de vendas realizadas, incentivando a melhoria no
          desempenho.</li>
        <li><strong>Premiações:</strong> Bônus oferecidos por atingimento de metas ou excelência em alguma área
          específica do trabalho.</li>
      </ul>
      <p class="large">Essas variáveis têm como objetivo direto estimular o trabalhador a alcançar melhores resultados e
        aumentar a produtividade.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Resumindo</h3>
      </header>
      <p class="large">Enquanto os adicionais trabalhistas buscam compensar o desgaste imposto ao empregado por
        condições fora do comum de trabalho, as variáveis salariais servem como incentivo ao bom desempenho e à
        eficiência no trabalho. Ambos os tipos de pagamento têm papéis importantes na relação entre empregador e
        empregado, mas atuam de formas bastante distintas.</p>
      <p class="large">Por isso, é crucial que os trabalhadores entendam seus direitos e as diferentes formas de
        remuneração que podem integrar seu salário, não apenas para reivindicar o que é justo, mas também para otimizar
        seu desempenho no ambiente de trabalho.</p>
    </section>



    <section class="info-block">
      <header>
        <h2>Descontos Aplicados no Salário Bruto</h2>
      </header>
      <table>
        <thead>
          <tr>
            <th>Desconto</th>
            <th>Descrição</th>
            <th>Alíquota/Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>INSS</td>
            <td>Contribuição para a Previdência Social</td>
            <td>7,5% a 14%</td>
          </tr>
          <tr>
            <td>IRRF</td>
            <td>Imposto de Renda Retido na Fonte</td>
            <td>0% a 27,5%</td>
          </tr>
          <tr>
            <td>Plano de Saúde</td>
            <td>Desconto para o plano de saúde oferecido pela empresa</td>
            <td>Variável</td>
          </tr>
          <tr>
            <td>Vale-Transporte</td>
            <td>Desconto opcional para custear o transporte diário</td>
            <td>Até 6%</td>
          </tr>
          <tr>
            <td>Contribuições Sindicais</td>
            <td>Desconto para o sindicato da categoria</td>
            <td>Variável</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section class="info-block">
      <header>
        <h2>Tabela de Alíquotas do INSS</h2>
      </header>
      <table>
        <thead>
          <tr>
            <th>Faixa Salarial (R$)</th>
            <th>Alíquota</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Até 1.412,00</td>
            <td>7,5%</td>
          </tr>
          <tr>
            <td>De 1.412,01 até 2.666,68</td>
            <td>9%</td>
          </tr>
          <tr>
            <td>De 2.666,69 até 4.000,03</td>
            <td>12%</td>
          </tr>
          <tr>
            <td>De 4.000,04 até 7.786,02</td>
            <td>14%</td>
          </tr>
        </tbody>
      </table>
      <p class="large">* Para salários acima de R$ 7.786,02, a contribuição é limitada ao teto do INSS, que atualmente é
        de R$ 828,38.</p>
    </section>
    <section class="info-block">
      <header>
        <h2>Tabela de Alíquotas do IRRF</h2>
      </header>
      <table>
        <thead>
          <tr>
            <th>Base de Cálculo (R$)</th>
            <th>Alíquota</th>
            <th>Parcela a Deduzir (R$)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Até 2.259,20</td>
            <td>Isento</td>
            <td>0,00</td>
          </tr>
          <tr>
            <td>De 2.259,21 até 2.826,65</td>
            <td>7,5%</td>
            <td>169,44</td>
          </tr>
          <tr>
            <td>De 2.826,66 até 3.751,05</td>
            <td>15%</td>
            <td>381,44</td>
          </tr>
          <tr>
            <td>De 3.751,06 até 4.664,68</td>
            <td>22,5%</td>
            <td>662,77</td>
          </tr>
          <tr>
            <td>Acima de 4.664,68</td>
            <td>27,5%</td>
            <td>896,00</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section class="info-block">
      <header>
        <h2>O FGTS Também é Descontado na Folha de Pagamento?</h2>
      </header>
      <p class="large">Não, o FGTS (Fundo de Garantia do Tempo de Serviço) não é descontado da folha de pagamento do
        empregado.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Como Funciona o FGTS?</h3>
      </header>
      <p class="large"><strong>Responsabilidade da Empresa:</strong> O FGTS é um valor que deve ser depositado
        mensalmente pelo empregador em uma conta vinculada ao funcionário. Esses depósitos correspondem a um percentual
        do salário do empregado.</p>
      <p class="large"><strong>Obrigatório por Lei:</strong> As empresas são obrigadas, por lei, a realizar esses
        depósitos. O valor depositado pode aparecer no contracheque, mas não representa um desconto no salário do
        funcionário.</p>
      <p class="large"><strong>Transparência:</strong> A discriminação do FGTS no contracheque serve apenas para que o
        funcionário possa acompanhar os depósitos feitos pela empresa.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Esclarecendo o Equívoco</h3>
      </header>
      <p class="large">O valor do FGTS não sai do bolso do empregado. Portanto, embora seja um direito importante do
        trabalhador e esteja visível na folha de pagamento para fins de transparência, este valor é uma responsabilidade
        financeira da empresa contratante.</p>
    </section>
    <section class="info-block">
      <header>
        <h2>Os descontos obrigatórios incidem em comissões, corretagem e outros ganhos variáveis do trabalhador?</h2>
      </header>
      <p class="large">Sim, os descontos obrigatórios também se aplicam a comissões, corretagem e outros ganhos
        variáveis do trabalhador. Contudo, é importante entender que as alíquotas, ou taxas de desconto, podem variar
        dependendo do tipo de remuneração.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Tipos de Descontos Aplicáveis</h3>
      </header>
      <p class="large"><strong>INSS (Instituto Nacional do Seguro Social):</strong> Se aplica a praticamente todo tipo
        de rendimento, inclusive comissões. As alíquotas variam de acordo com a faixa salarial do trabalhador.</p>
      <p class="large"><strong>IRRF (Imposto de Renda Retido na Fonte):</strong> Incide sobre os rendimentos e é
        calculado conforme a tabela progressiva do imposto de renda. Também afeta ganhos variáveis, como comissões e
        corretagem.</p>
      <p class="large"><strong>Contribuição Sindical:</strong> Pode incidir sobre comissões e outros ganhos, dependendo
        do acordo coletivo da categoria.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Exemplo Prático</h3>
      </header>
      <p class="large">Se um trabalhador recebe um salário fixo e, adicionalmente, comissões mensais, estas comissões
        serão somadas ao salário para cálculo dos descontos. Portanto, ele pagará INSS e IRRF sobre o valor total
        recebido.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Considerações Importantes</h3>
      </header>
      <ul>
        <li><strong>Base de Cálculo:</strong> A base de cálculo para alguns descontos pode mudar conforme o tipo de
          ganho.</li>
        <li><strong>Variação das Alíquotas:</strong> A porcentagem descontada varia conforme o valor dos rendimentos e
          próprio tipo de ganho.</li>
        <li><strong>Acordos Coletivos:</strong> Algumas categorias têm regras específicas estabelecidas em acordos
          coletivos.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h3>Conclusão</h3>
      </header>
      <p class="large">Sim, comissões, corretagem e outros ganhos variáveis do trabalhador estão sujeitos a descontos
        obrigatórios. No entanto, as alíquotas podem variar dependendo do tipo de ganho e da faixa salarial.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>Pensão Alimentícia: Desconta no Salário Bruto ou Líquido?</h2>
      </header>
      <p class="large">Quando se trata da pensão alimentícia, a dúvida mais comum é se ela incide sobre o salário bruto
        ou o líquido. A resposta não é tão simples e pode variar conforme a decisão judicial.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Considerações Legais</h3>
      </header>
      <p class="large">Geralmente, muitos tribunais optam por calcular o valor da pensão com base no salário líquido,
        pois esse valor reflete o que o responsável realmente recebe após todas as deduções legais, como INSS e Imposto
        de Renda.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Casos Específicos</h3>
      </header>
      <p class="large">No entanto, há situações em que o juiz pode determinar que a pensão seja calculada a partir do
        salário bruto. Esse tipo de determinação pode levar em conta casos específicos e as necessidades do
        beneficiário.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Variáveis Determinantes</h3>
      </header>
      <ul>
        <li><strong>Situação Econômica:</strong> A condição financeira do pagador e do beneficiário pode influenciar a
          decisão.</li>
        <li><strong>Proporção do Salário:</strong> O percentual combinado ou determinado pode afetar se o cálculo será
          bruto ou líquido.</li>
        <li><strong>Acórdão Judicial:</strong> A sentença final, baseada em análises detalhadas de ambos os lados, dará
          o veredito específico para cada caso.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h3>Conclusão</h3>
      </header>
      <p class="large">Em resumo, a incidência da pensão alimentícia no salário bruto ou líquido é determinada caso a
        caso e depende das especificidades de cada situação. Fatores como a análise econômica e a decisão do juiz são
        cruciais nesse processo.</p>
    </section>

    <section class="info-block">
      <header>
        <h2>A Contribuição Sindical é Obrigatória?</h2>
      </header>
      <p class="large">A contribuição sindical deixou de ser obrigatória. Com as recentes mudanças nas leis
        trabalhistas, essa contribuição agora é opcional.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>O Que Isso Significa na Prática?</h3>
      </header>
      <p class="large">Antigamente, todos os trabalhadores eram obrigados a pagar uma taxa anual ao sindicato
        correspondente à sua categoria profissional. Isso mudou.</p>
      <p class="large">Agora, cada trabalhador tem a liberdade de escolher se deseja ou não contribuir com o sindicato.
        Essa decisão pode ser tomada individualmente, sem nenhuma imposição legal.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Por Que a Mudança?</h3>
      </header>
      <p class="large">Essa alteração foi implementada para proporcionar mais autonomia aos trabalhadores, permitindo
        que decidam se querem apoiar a organização sindical de sua categoria.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Como Funciona Agora?</h3>
      </header>
      <ul>
        <li><strong>Voluntariedade:</strong> A contribuição sindical só será descontada se o trabalhador autorizar de
          forma explícita.</li>
        <li><strong>Controle Individual:</strong> Cada empregado pode fazer essa escolha diretamente, sem pressão
          externa.</li>
        <li><strong>Implicações:</strong> Embora a contribuição tenha se tornado opcional, ela ainda pode ser importante
          para o fortalecimento do sindicato e para a defesa dos interesses dos trabalhadores.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h3>Conclusão</h3>
      </header>
      <p class="large">Em resumo, a contribuição sindical não é mais obrigatória, dando mais poder de decisão aos
        trabalhadores sobre onde colocar seus recursos financeiros.</p>
    </section>


    <section class="info-block">
      <header>
        <h2>O que é um Empréstimo Consignado?</h2>
      </header>
      <p class="large">Um empréstimo consignado é uma modalidade de crédito na qual as parcelas são descontadas
        automaticamente do salário ou benefício de quem o contrata.</p>
    </section>
    <section class="info-block">
      <header>
        <h3>Principais características</h3>
      </header>
      <ul>
        <li><strong>Desconto em folha:</strong> O valor da parcela é diretamente deduzido do pagamento mensal, seja ele
          salário, aposentadoria ou pensão.</li>
        <li><strong>Taxas atrativas:</strong> Geralmente, essa modalidade oferece taxas de juros mais baixas em
          comparação a outros tipos de empréstimos devido à garantia de pagamento direto.</li>
        <li><strong>Facilidade de aprovação:</strong> Com o desconto garantido em folha, as instituições financeiras
          encontram menor risco, facilitando a aprovação do crédito.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h3>Vantagens</h3>
      </header>
      <ul>
        <li><strong>Conveniência:</strong> Não é necessário se preocupar com o pagamento mensal, já que ocorre
          automaticamente.</li>
        <li><strong>Juros baixos:</strong> As taxas são mais acessíveis, tornando o custo total do empréstimo mais
          vantajoso.</li>
      </ul>
      <p class="large">A simplicidade e a segurança para as instituições financeiras fazem do empréstimo consignado uma
        opção atrativa para quem busca crédito com melhores condições.</p>
    </section>



    <section class="info-block">
      <header>
        <h2>Exemplo de Cálculo de Salário Líquido</h2>
      </header>
      <p class="large">Suponha que um empregado tenha um salário bruto de R$ 5.000,00. Aplicando os descontos:</p>
      <ul>
        <li><strong>INSS:</strong> R$ 518,81</li>
        <li><strong>IRRF:</strong> R$ 335,15</li>
        <li><strong>Plano de Saúde:</strong> R$ 200,00</li>
        <li><strong>Vale Transporte:</strong> R$ 150,00</li>
      </ul>
      <p class="large">O salário líquido será: R$ 5.000,00 - R$ 1.203,96 = R$ 3.796,04.</p>
    </section>



    <section class="info-block">
      <header>
        <h2>Dicas para Maximizar o Salário Líquido</h2>
      </header>
      <p class="large">Para maximizar o salário líquido, é importante:</p>
      <ul>
        <li>Optar por benefícios que não sejam descontados diretamente no salário.</li>
        <li>Aproveitar deduções legais no IRRF, como dependentes e despesas médicas.</li>
        <li>Buscar alternativas de transporte que não demandem o vale-transporte.</li>
        <li>Investir em planos de saúde mais acessíveis.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h2>Benefícios do Planejamento Financeiro com o Salário Líquido</h2>
      </header>
      <p class="large">Compreender seu salário líquido é fundamental para um bom planejamento financeiro. Conhecer os
        valores exatos de seus recebimentos permite que você:</p>
      <ul>
        <li>Organize seu orçamento mensal de maneira mais eficiente.</li>
        <li>Planeje investimentos e poupanças com maior precisão.</li>
        <li>Evite surpresas com deduções não previstas no salário.</li>
      </ul>
    </section>
    <section class="info-block">
      <header>
        <h2>Legislação e Atualizações</h2>
      </header>
      <p class="large">É importante estar sempre atualizado sobre as mudanças na legislação trabalhista e tributária,
        pois elas podem afetar diretamente o cálculo do salário líquido. Consulte regularmente fontes oficiais, como a
        Receita Federal e o Ministério do Trabalho, para se manter informado sobre novas alíquotas, faixas de
        contribuição e outras atualizações relevantes.</p>
    </section>
  </div>


</template>

<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import PieChart from '@/components/calculator/pieChart.vue';
//import adSquare from '@/components/monetization/adSquare.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
  name: 'SalarioLiquidoCLT',
  components: {
    CalculatorHeader,
    AppButton,
    BreadCrumb,
    OtherCalculators,
    PieChart,
    adHorizontal
  },
  data() {
    return {
      breadCrumbItems: [
        { label: 'Calculadora de Salário Líquido CLT', url: '/calculadora-salario-liquido-clt' }
      ],
      grossSalary: 0,
      formattedGrossSalary: '',
      dependents: 0,
      benefits: 0,
      formattedBenefits: '',
      otherDeductions: 0,
      formattedOtherDeductions: '',
      netSalary: null,
      formattedINSS: '',
      formattedIRRF: '',
      formattedTotalDescontos: '',
      formattedProventos: '',
      resultsVisible: false,
      staticGrossSalary: '',
      staticBenefits: '',
      staticOtherDeductions: '',
    };
  },
  methods: {
    calculateNetSalary() {
      if (this.formattedBenefits == '') this.formattedBenefits = 'R$ 0,00';
      if (this.formattedOtherDeductions == '') this.formattedOtherDeductions = 'R$ 0,00';
      const grossSalaryNumber = this.unformatCurrency(this.formattedGrossSalary);
      const benefitsNumber = this.unformatCurrency(this.formattedBenefits);
      const otherDeductionsNumber = this.unformatCurrency(this.formattedOtherDeductions);
      const inss = this.calculateINSS(grossSalaryNumber);
      const irrf = this.calculateIRRF(grossSalaryNumber - inss - this.dependents * 189.59);
      const totalDescontos = inss + irrf + otherDeductionsNumber;
      const proventos = grossSalaryNumber + benefitsNumber;
      this.netSalary = (proventos - totalDescontos).toFixed(2);
      this.netSalary = this.formatCurrency(this.netSalary * 100);
      this.formattedINSS = this.formatCurrency(inss * 100);
      this.formattedIRRF = this.formatCurrency(irrf * 100);
      this.formattedTotalDescontos = this.formatCurrency(totalDescontos * 100);
      this.formattedProventos = this.formatCurrency(proventos * 100);

      // Atualiza os valores estáticos
      this.staticGrossSalary = this.formattedGrossSalary;
      this.staticBenefits = this.formattedBenefits;
      this.staticOtherDeductions = this.formattedOtherDeductions;
      this.resultsVisible = true;
      this.scrollToResult();
    },
    calculateINSS(salary) {
      if (salary <= 1412.00) return salary * 0.075;
      if (salary <= 2666.68) return (1412.00 * 0.075) + ((salary - 1412.00) * 0.09);
      if (salary <= 4000.03) return (1412.00 * 0.075) + ((2666.68 - 1412.00) * 0.09) + ((salary - 2666.68) * 0.12);
      if (salary <= 7786.02) return (1412.00 * 0.075) + ((2666.68 - 1412.00) * 0.09) + ((4000.03 - 2666.68) * 0.12) + ((salary - 4000.03) * 0.14);
      return 908.86; // Teto do INSS
    },
    calculateIRRF(salary) {
      if (salary <= 2259.20) return 0;
      if (salary <= 2826.65) return (salary * 0.075) - 169.44;
      if (salary <= 3751.05) return (salary * 0.15) - 381.44;
      if (salary <= 4664.68) return (salary * 0.225) - 662.77;
      return (salary * 0.275) - 896.00;
    },
    onGrossSalaryInput(event) {
      const value = event.target.value.replace(/\D/g, '');
      this.formattedGrossSalary = this.formatCurrency(value);
    },
    onBenefitsInput(event) {
      const value = event.target.value.replace(/\D/g, '');
      this.formattedBenefits = this.formatCurrency(value);
    },
    onOtherDeductionsInput(event) {
      const value = event.target.value.replace(/\D/g, '');
      this.formattedOtherDeductions = this.formatCurrency(value);
    },
    formatCurrency(value) {
      let val = (value / 100).toFixed(2).replace('.', ',');
      return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    unformatCurrency(value) {
      return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
    },
    scrollToResult() {
      const resultSection = this.$refs.result;
      if (resultSection) {
        resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};
</script>


<style lang="scss" scoped></style>
