<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div>
        <section class="wrapper content">
            <header>
                <h1>Sobre Calculico</h1>
                <p class="x-large">Calculadoras e ferramentas para facilitar seu trabalho.
                </p>
            </header>
            <div>
                <p><strong>Calculico nasceu para ser um facilitador.</strong></p>
                <p>Uma coleção de calculadoras e ferramentas para você ter sempre a mão e, assim, facilitar tarefas e cálculos que te ajudam no dia-a-dia, no trabalho, no planejamento e tomada de decisões para suas finanças pessoais, corporativas, saúde e estudos.</p>
                <p>Estamos em constante expansão, sempre desenvolvendo e publicando novas calculadoras. Queremos ouvir e ajudar você, então, não hesite em solicitar uma calculadora específica que você ainda não tenha conseguido encontrar aqui no Calculico.</p>
                <p>Este é um projeto desenvolvido de forma independente, por isso, dependemos de anunciantes e do seu apoio para continuar desenvolvendo a maior plataforma de calculadoras do Brasil.</p>
            </div>
        </section>
    </div>
</template>

<script>
import BreadCrumb from '@/components/layout/breadCrumb.vue';

export default {
    name: 'AboutPage',
    components: {
        BreadCrumb
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Sobre', url: '/sobre' }
            ]
        };
    },
    methods: {
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    // Other component options go here
};
</script>

<style lang="scss" scoped>
.content{
    padding-top: 8rem;
    max-width:800px ;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin: 0 auto;

    p{
        margin-bottom: 2rem;
    }
}
</style>