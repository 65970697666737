<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div class="wrapper content">
        <header>
                <h1>Fale Conosco</h1>
                <p class="x-large">Mande sua mensagem no formulário abaixo:
                </p>
            </header>
        <div class="form-body form-section" :class="{ loading: loading }">
            <form @submit.prevent="sendMessage">
                <div class="form-group">
                    <label for="name">Nome:</label>
                    <input type="text" v-model="name" id="name" required />
                </div>
                <div class="form-group">
                    <label for="email">Email:</label>
                    <input type="email" v-model="email" id="email" required />
                </div>
                <div class="form-group">
                    <label for="subject">Assunto:</label>
                    <select v-model="subject" id="subject" required>
                        <option value="Anúncios e parcerias">Anúncios e parcerias</option>
                        <option value="Dúvida">Dúvida</option>
                        <option value="Reclamação">Reclamação</option>
                        <option value="Solicitar Calculadora">Solicitar Calculadora</option>
                        <option value="Reportar Erro">Reportar Erro</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="message">Mensagem:</label>
                    <textarea v-model="message" id="message" required></textarea>
                </div>
                <AppButton label="Enviar" type="primary" size="large" />
            </form>
            <div v-if="formSubmitted" class="alert-success">
                <img src="@/assets/images/icons/check-circle-solid.svg" alt="">
                <p><strong>Mensagem enviada com sucesso!</strong> Obrigado por entrar em contato. Responderemos em
                    breve.</p>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import axios from 'axios';

export default {
    name: 'ContactPage',
    components: {
        AppButton,
        BreadCrumb,
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Contato', url: '/contato' }
            ],
            name: '',
            email: '',
            subject: '',
            message: '',
            formSubmitted: false,
            loading: false,
        };
    },
    methods: {
        async sendMessage() {
            const contactMessage = {
                name: this.name,
                userEmail: this.email,
                subject: this.subject,
                message: this.message,
            };

            try {
                this.loading = true;
                await axios.post('https://us-central1-calculico-gsk.cloudfunctions.net/sendEmail', contactMessage);
                this.formSubmitted = true;
                this.loading = false;
                this.name = '';
                this.message = '';
                this.email = '';
                this.subject = '';
                setTimeout(() => {
                    this.formSubmitted = false;
                }, 8000);
            } catch (error) {
                console.error('Error sending message:', error);
                this.formError = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 4px;
}

.form-section {
    padding: 1.6rem 0 2.4rem 0;
    gap: 2rem;
    width: 100%;

    @include bp(mobile) {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 1.2rem;
    }

    @include bp(tablet) {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 1.2rem;
    }

    @include bp(small-desktop) {
        width: 80%;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    }
}

.form-body {
    text-align: left;

    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }
}
.content{
    padding-top: 8rem;
    max-width:800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 2.4rem;
}
</style>