<template>
  <section class="wrapper hero">

    <header>
      <h1>O que você quer calcular hoje?</h1>
      <p class="x-large">Encontre a calculadora certa para todas as suas necessidades diárias, financeiras e de saúde.</p>
    </header>
    <input type="text" v-model="searchQuery"
      placeholder="Procure pelo tipo de coisa que você quer calcular (e.g. “salário liquido”, “clt”, “juros compostos”)" />

  </section>
  <div class="content">
    <div v-if="filteredCategories.length === 0" class="wrapper category">
      <searchEmpty :query="searchQuery" />
    </div>
    <div v-else v-for="category in filteredCategories" :key="category.id" class="wrapper category">
      <div class="category-header">
        <div class="category-title">
          <img :src="category.icon" alt="Category Icon" class="category-icon" />
          <h3>{{ category.name }}</h3>
        </div>
        <p class="large">{{ category.subtitle }}</p>
      </div>
      <div class="calculator-cards">
        <CalculatorCard v-for="calculator in category.calculators" :key="calculator.id" :emoji="calculator.emoji"
          :title="calculator.title" :description="calculator.description" :pageUrl="calculator.pageUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CalculatorCard from '@/components/calculator/calculatorCard.vue';
import searchEmpty from '@/components/layout/searchEmpty.vue';

export default {
  name: 'HomePage',
  components: {
    CalculatorCard,
    searchEmpty
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters(['filteredCategories']),
    filteredCategories() {
      return this.$store.getters.filteredCategories(this.searchQuery);
    }
  }
};
</script>

<style lang="scss" scoped>

.hero {
  text-align: left;
  padding-top: 4rem;
  padding-bottom: 8rem;
  border-bottom: 1px solid $primary-1;
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  background: linear-gradient(179deg, rgba(255, 255, 255, 0.3) -60%, #FFF 7.61%, rgba(255, 255, 255, 0.65) 100%, rgba(255, 255, 255, 0) 100%), radial-gradient(182.29% 544.7% at 36.87% 199.91%, #F6F7FE 0%, rgba(255, 255, 255, 0) 100%), url("@/assets/images/calculator-pattern.png") lightgray 0% 0% / 400px 400px repeat;

  header {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  h1 {
    color: $primary-7;
  }

  p {
    font-weight: 500;
  }

  @include bp(mobile) {
    padding-top: 2rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
  }
}

.category{
  &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
      background: none;
    }
}
</style>
