<template>
    <div class="category other-calculators">
      <div v-if="otherCalculators.length" class="wrapper">
        <div class="category-header">
          <div class="category-title">
            <img src="@/assets/images/icons/list-solid.svg" width="32" alt="Category Icon" class="category-icon" />
            <h3>{{ title }}</h3>
          </div>
          <p class="large">{{ description }}</p>
        </div>
        <div class="calculator-cards">
          <CalculatorCard
            v-for="calculator in otherCalculators"
            :key="calculator.id"
            :emoji="calculator.emoji"
            :title="calculator.title"
            :description="calculator.description"
            :pageUrl="calculator.pageUrl"
            bgStyle="white"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import CalculatorCard from '@/components/calculator/calculatorCard.vue';
  
  export default {
    name: 'OtherCalculators',
    components: {
      CalculatorCard
    },
    props: {
      categoryId: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        default: 'Outras calculadoras'
      },
      description: {
        type: String,
        default: 'Você pode gostar de experimentar calculadoras relacionadas:'
      }
    },
    computed: {
      ...mapGetters(['getOtherCalculators']),
      otherCalculators() {
        const calculators = this.getOtherCalculators(this.categoryId);
        return this.shuffleArray(calculators).slice(0, 4);
      }
    },
    methods: {
      shuffleArray(array) {
        let shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
      }
    }
  };
  </script>
  
<style lang="scss" scoped>

</style>
  