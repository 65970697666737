<template>
    <nav class="breadcrumb wrapper">
        <ul>
            <li class="home">
                <img src="../../assets/images/icons/home-solid.svg" width="20" alt="">
                <a href="../">Home</a>
                <img src="../../assets/images/icons/chevron-simple-right-solid.svg" alt="">
            </li>
            <li v-for="(item, index) in items" :key="index" class="breadcrumb-item">
                <a :href="item.url">{{ item.label }}</a>
                <img src="../../assets/images/icons/chevron-simple-right-solid.svg" alt="">
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'BreadCrumb',
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    display: flex;
    flex-direction: row;
    background-color: $neutral-0;
    border-bottom: 1px solid $neutral-1;
    max-height: 72px;
    font-family: $titlesFont;

    a {
        color: #1430B8;
        text-decoration: none;
        font-weight: 500;

        &:hover {
            color:#0A185C;
            font-weight: 700;
        }
    }

    @extend p;

    ul {
        display: flex;
        list-style: none;
        gap: 1.2rem;
        padding: 0;
        align-items: center;

        @include bp(mobile) {
            gap: 0.8rem;
        }

        li {
            display: flex;
            align-items: center;

            &.home {
                img {
                    &:first-of-type {
                        margin-right: 1.2rem;
                    }
                    &:last-of-type{
                        margin-left: 0.8rem;
                    }
                }
            }

            &.breadcrumb-item {
                &:last-of-type {
                    img {
                        display: none;
                    }
                }

                img {
                    margin: 0 0.8rem;
                }
            }
        }
    }
}
</style>