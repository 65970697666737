<template>
    <div class="ad-google-horizontal"></div>
  </template>
  
  <script>
  export default {
    name: 'AdHorizontal',
    mounted() {
      this.loadAd();
    },
    methods: {
      loadAd() {
        const adScript = document.createElement('script');
        adScript.async = true;
        adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7643872173055001';
        adScript.crossOrigin = 'anonymous';
        document.body.appendChild(adScript);
  
        adScript.onload = () => {
        // eslint-disable-next-line
          (adsbygoogle = window.adsbygoogle || []).push({});
        };
  
        const adDiv = this.$el;
        adDiv.innerHTML = `
          <ins class="adsbygoogle"
               style="display:block"
               data-ad-client="ca-pub-7643872173055001"
               data-ad-slot="8022308980"
               data-ad-format="auto"
               data-full-width-responsive="true"></ins>
        `;
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  