<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div>
        <section class="wrapper content">
            <header>
                <h1>Termos de Uso</h1>
                <p class="x-large">Última atualização e publicação: 19 de Junho de 2024</p>
            </header>
            <div>
                <h4>1. Aceitação dos Termos</h4>
                <p>Ao acessar e usar o site Calculico, você concorda com os seguintes Termos de Uso. Se você não concordar com qualquer parte destes termos, por favor, não use nosso site.</p>
                <h4>2. Modificações dos Termos</h4>
                <p>O Calculico se reserva o direito de alterar estes Termos de Uso a qualquer momento. Quaisquer alterações serão publicadas nesta página e entrarão em vigor imediatamente após a publicação.</p>
                <h4>3. Uso do Site</h4>
                <p>Você concorda em usar o Calculico apenas para fins legais e de acordo com estes Termos de Uso. É proibido usar o site de qualquer maneira que possa prejudicar, desativar, sobrecarregar ou comprometer o funcionamento do site.</p>
                <h4>4. Propriedade Intelectual</h4>
                <p>Todo o conteúdo do site, incluindo, mas não se limitando a textos, gráficos, logotipos, ícones e imagens, é de propriedade do Calculico ou de seus fornecedores de conteúdo e é protegido por leis de direitos autorais.</p>
                <h4>5. Privacidade</h4>
                <p>O uso do Calculico também é regido por nossa Política de Privacidade, que descreve como coletamos, usamos e protegemos suas informações pessoais.</p>
                <h4>6. Limitação de Responsabilidade</h4>
                <p>O Calculico não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, consequentes ou punitivos decorrentes do uso ou da incapacidade de usar o site.</p>
                <h4>7. Links para Sites de Terceiros</h4>
                <p>O site pode conter links para sites de terceiros. O Calculico não é responsável pelo conteúdo ou práticas de privacidade desses sites.</p>
                <h4>8. Encerramento do Acesso</h4>
                <p>O Calculico se reserva o direito de encerrar ou suspender seu acesso ao site, sem aviso prévio, por qualquer motivo, incluindo, mas não se limitando a, violação destes Termos de Uso.</p>
                <h4>9. Lei Aplicável</h4>
                <p>Estes Termos de Uso são regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa decorrente destes termos será submetida exclusivamente aos tribunais competentes no Brasil.</p>
                <h4>10. Contato</h4>
                <p>Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail: <a href="mailto:contato@calculico.com.br">contato@calculico.com.br</a>.</p>
            </div>
        </section>
    </div>
</template>

<script>
import BreadCrumb from '@/components/layout/breadCrumb.vue';

export default {
    name: 'TermsOfUse',
    components: {
        BreadCrumb
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Termos de Uso', url: '/termos' }
            ]
        };
    },
    methods: {
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    // Other component options go here
};
</script>

<style scoped>
.content{
    padding-top: 8rem;
    max-width:800px ;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin: 0 auto;

    p{
        margin-bottom: 2rem;
    }
    h4{
        margin-bottom: 1.2rem;
    }
}
</style>