import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VueGtm from 'vue-gtm';

const app = createApp(App);

app.use(VueGtm, {
  id: 'GTM-THHPBBMM',
  vueRouter: router,
  enabled: true,
  debug: true,
  loadScript: true,
});

app.use(store).use(router).mount('#app');
